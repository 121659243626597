import HeaderA from "./HeaderA";
import FooterA from "./FooterA";
import SidebarA from "./SidebarA";
import { useState, useEffect, useContext } from "react";
import { myContext } from "../MyContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getCookie } from "../Cookie";

const CustomerDetailsCard = () => {
  const [formData, setFormData] = useState({});
  const context = useContext(myContext);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState([]);
  const [rechargeDetails, setRechargeDetails] = useState("");
  const [rechargeStatusDetails, setRechargeStatusDetails] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [firstPart, setFirstPart] = useState(true);
  const cookie = getCookie();
  const { id } = useParams();
  var serial = 1;

  useEffect(() => {
    console.log(openModal);
  }, [openModal]);
  useEffect(() => {
    if (rechargeDetails) {
      document.querySelector("#DialogBasic").style.display = "block";
    }
  }, [rechargeDetails]);
  useEffect(() => {
    if (!cookie.aid) {
      navigate("/");
    }

    axios
      .get("https://api.grewbig.com/index.php/item/getCustomerDetailsCard")
      .then((d) => {
        setUserDetails(d.data);
        console.log(d);
      })
      .catch((e) => console.log(e));
    //     axios
    //       .post("https://api.grewbig.com/index.php/payment/paymentstat", data)
    //       .then((d) => {
    //         setRechargeDetails(d.data);
    //         axios
    //           .post(
    //             "https://api.grewbig.com/index.php/payment/rechargestat",
    //             data
    //           )
    //           .then((d) => {
    //             setRechargeStatusDetails(d.data);
    //           })
    //           .catch((e) => console.log(e));
    //       })
    //       .catch((e) => console.log(e));
    //   })
    //   .catch((e) => console.log(e));
  }, []);

  return (
    <div>
      <HeaderA />
      {openModal ? (
        true ? (
          <div
            className="modal show dialogbox"
            id="DialogBasic"
            data-bs-backdrop="static"
            tabindex="-1"
            role="dialog"
            style={{ display: "none", background: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="modal-dialog" role="document">
              <div
                className="modal-content"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "10px",
                  maxWidth: "375px",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    fontSize: "15px",
                    marginBottom: "20px",
                  }}
                  align="center"
                >
                  Do Card Payment
                  <br />
                  <table className="pplan">
                    <tr>
                      <td>Card No</td>
                      <td>
                        {rechargeDetails.cardNo ? rechargeDetails.cardNo : ""}
                      </td>
                    </tr>
                  </table>
                  <button
                    className="btn btn-md btn-primary"
                    style={{ marginTop: "8px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      var data = new FormData();
                      data.append("id", rechargeDetails.transaction_id);

                      axios
                        .post(
                          "https://api.grewbig.com/index.php/item/addplandurationcard",
                          data
                        )
                        .then((d) => {
                          //   document.querySelector("#successp").style.display =
                          //     "block";
                          alert("Card Bill Pay Successfully");

                          axios
                            .get(
                              "https://api.grewbig.com/index.php/item/getCustomerDetailsCard"
                            )
                            .then((d) => {
                              setUserDetails(d.data);
                              console.log(d);
                              setRechargeDetails("");
                              document.querySelector(
                                "#DialogBasic"
                              ).style.display = "none";
                            })
                            .catch((e) => console.log(e));
                        })
                        .catch((e) => console.log(e));
                    }}
                  >
                    Bill Pay
                  </button>
                  <button
                    className="btn btn-md btn-warning"
                    style={{ marginTop: "8px", marginLeft: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenModal(false);
                      document.querySelector("#DialogBasic").style.display =
                        "none";
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      <div id="appCapsule">
        <div align="center" style={{ marginTop: "15px" }}>
          <div
            class="pageTitle badge badge-md badge-primary"
            style={{ fontSize: "20px", padding: "15px" }}
            align="center"
          >
            Bill Pay Requests
          </div>
        </div>
        <button
          className="btn btn-md btn-warning"
          onClick={(d) => {
            d.preventDefault();
            window.location.reload();
          }}
        >
          Refresh
        </button>
        <div className="section mb-5 p-2">
          <div class="card">
            <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
              {userDetails.length
                ? userDetails.map((val, index) => {
                    return (
                      <div class="credit-card">
                        <div class="bank-name">{val.bank}</div>
                        <div class="card-number">{val.creditCardNo}</div>
                        <div class="amount-pending">Name: {val.name}</div>
                        <div class="amount-pending">
                          Bill Amount: {val.actualamount}
                        </div>
                        <div class="amount-pending">
                          Cashback Earned: {val.cashbackearn}
                        </div>
                        <div class="amount-pending">
                          Cashback Used: {val.cashbackuse}
                        </div>
                        <div class="amount-pending">Mobile: {val.mobile}</div>
                        <div class="pay-now-container">
                          <div class="date-transaction">
                            Date & Time:{" "}
                            {val.date
                              .substring(0, 10)
                              .split("/")
                              .reverse()
                              .join("/") +
                              " |" +
                              val.date.substring(10)}
                          </div>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenModal(true);
                              setRechargeDetails({
                                cardNo: val.creditCardNo,
                                transaction_id: val.transaction_id_g,
                              });
                            }}
                            class="pay-now-button"
                          >
                            Pay Now
                          </a>
                        </div>
                      </div>
                      // <li key={val.id}>
                      //   <a href="#" class="item">
                      //     <div class="in">
                      //       <div>
                      //         <strong>
                      //           Name: {val.name} | Card No: {val.creditCardNo}
                      //         </strong>
                      //         <div class="text-small text-secondary">
                      //           Bill Amount: {val.actualamount} | Bank:{" "}
                      //           {val.bank}
                      //         </div>
                      //         <div class="text-small text-secondary">
                      //           Transaction ID: {val.transaction_id_g} |
                      //           Customer Mobile: {val.mobile}
                      //         </div>
                      //         <div class="text-small text-secondary">
                      //           Cashback Earn: {val.cashbackearn} | Cashback
                      //           Use: {val.cashbackuse} | Amount Paid:{" "}
                      //           {val.amount}
                      //         </div>
                      //       </div>
                      //       <div class="text-end">
                      //         <button
                      //           className="btn btn-sm btn-success"
                      //           onClick={(e) => {
                      //             e.preventDefault();
                      //             setOpenModal(true);
                      //             setRechargeDetails({
                      //               cardNo: val.creditCardNo,
                      //               transaction_id: val.transaction_id_g,
                      //             });
                      //           }}
                      //         >
                      //           Bill Pay
                      //         </button>
                      //       </div>
                      //     </div>
                      //   </a>
                      // </li>
                    );
                  })
                : "No Data Found"}
            </ul>
          </div>
        </div>
      </div>

      {/* App Bottom Menu */}
      <FooterA />
      {/* * App Bottom Menu */}

      {/* App Sidebar */}
      <SidebarA />
      {/* * App Sidebar */}
    </div>
  );
};

export default CustomerDetailsCard;
