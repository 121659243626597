// import { useEffect, useState } from "react";
// import axios from "axios";
// import { Button } from "framework7-react";
// import sha256 from "crypto-js/sha256";
// import { Buffer } from "buffer";
// import { useNavigate, useParams } from "react-router-dom";

// function Test() {
//   const [img, setImg] = useState();
//   const [otp, setOtp] = useState(30);
//   const { id } = useParams();
//   const navigate = useNavigate();
//   function sendData(e) {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append("image", img);
//     axios
//       .post("https://api.grewbig.com/index.php/item", formData)
//       .then((res) => console.log(res))
//       .catch((c) => console.log(c));
//   }
//   const salt_key = "90e81706-ebc1-4207-b1b5-d9a7514c131b";
//   const merchant_id = "M22NJDRPOSAIA";

//   function apitest() {
//     try {
//       var merchantUserId = "MUID" + Date.now();
//       var merchantTransactionId = "T" + Date.now();
//       const data = {
//         merchantId: merchant_id,
//         merchantTransactionId: merchantTransactionId,
//         merchantUserId: merchantUserId,
//         name: "abhishek",
//         amount: 1 * 100,
//         redirectUrl: `http://localhost:3000/${merchantTransactionId}`,
//         redirectMode: "GET",
//         mobileNumber: "9045370126",
//         paymentInstrument: {
//           type: "PAY_PAGE",
//         },
//       };
//       const payload = JSON.stringify(data);
//       const payloadMain = Buffer.from(payload).toString("base64");
//       const keyIndex = 1;
//       const string = payloadMain + "/pg/v1/pay" + salt_key;
//       const sha2561 = sha256(string);
//       const checksum = sha2561 + "###" + keyIndex;
//       console.log(checksum);

//       const prod_URL = "https://api.phonepe.com/apis/hermes/pg/v1/pay";
//       const options = {
//         method: "POST",
//         url: prod_URL,
//         headers: {
//           accept: "application/json",
//           "Content-Type": "application/json",
//           "X-VERIFY": checksum,
//         },
//         data: {
//           request: payloadMain,
//         },
//       };

//       axios
//         .request(options)
//         .then(function (response) {
//           console.log(response.data);
//           window.location =
//             response.data.data.instrumentResponse.redirectInfo.url;
//         })
//         .catch(function (error) {
//           console.error(error);
//         });
//     } catch (error) {
//       alert(error);
//     }
//   }
//   // useEffect(() => {
//   //   var marID = "M22NJDRPOSAIA";
//   //   var sh =
//   //     sha256(
//   //       `/pg/v1/status/${marID}/${id}` + "90e81706-ebc1-4207-b1b5-d9a7514c131b"
//   //     ) +
//   //     "###" +
//   //     1;

//   //   axios
//   //     .get(`https://api.phonepe.com/apis/hermes/pg/v1/status/${marID}/${id}`, {
//   //       headers: {
//   //         accept: "application/json",
//   //         "Content-Type": "application/json",
//   //         "X-verify": sh,
//   //         "X-MERCHANT-ID": marID,
//   //       },
//   //     })
//   //     .then((d) => {
//   //       console.log(d);
//   //       if (d.data.code == "PAYMENT_SUCCESS") {
//   //         alert("payment Success");
//   //       } else {
//   //         alert("payment failed");
//   //       }
//   //     })
//   //     .catch((c) => console.log(c));
//   // });
//   // function testAPI(e) {
//   //   e.preventDefault();
//   //   const time = new Date().getTime() + "";
//   //   const transactionID =
//   //     "GW" +
//   //     Math.floor(Math.random() * (1999 - 1000) + 1000) +
//   //     Math.floor(Math.random() * (4999 - 4000) + 4000) +
//   //     time.substr(7);

//   //   const payload = {
//   //     merchantId: "M22NJDRPOSAIA",
//   //     merchantTransactionId: transactionID,
//   //     merchantUserId: "MUID123003",
//   //     amount: 1 * 100,
//   //     redirectUrl: `http://localhost:3000/${transactionID}`,
//   //     redirectMode: "REDIRECT",
//   //     callbackUrl: `http://localhost:3000/${transactionID}`,
//   //     mobileNumber: "9045370126",
//   //     paymentInstrument: {
//   //       type: "PAY_PAGE",
//   //     },
//   //   };
//   //   const dataPayload = JSON.stringify(payload);
//   //   const database64 = Buffer.from(dataPayload).toString("base64");
//   //   const fullURL = database64 + "90e81706-ebc1-4207-b1b5-d9a7514c131b";
//   //   const datasha256 = sha256(fullURL);
//   //   const checksum = datasha256 + "###" + 1;
//   //   console.log(checksum);
//   //   const response = axios
//   //     .post(
//   //       "https://api.phonepe.com/apis/hermes/pg/v1/pay",
//   //       { request: database64 },
//   //       {
//   //         headers: {
//   //           accept: "application/json",
//   //           "Content-Type": "application/json",
//   //           "X-verify": checksum,
//   //         },
//   //       }
//   //     )
//   //     .then((d) => {
//   //       console.log(d);
//   //       window.location = d.data.data.instrumentResponse.redirectInfo.url;
//   //     })
//   //     .catch((c) => console.log(c));
//   // }
//   return (
//     <div>
//       <Button onClick={apitest}>Test API</Button>
//       <button
//         onClick={() => {
//           setInterval(() => {
//             setOtp((otp) => {
//               return otp - 1;
//             });
//           }, 1000);

//           console.log(otp);
//         }}
//       >
//         click
//       </button>
//       {otp}
//       <form>
//         <input
//           type="file"
//           onChange={(e) => {
//             setImg(e.target.files[0]);
//           }}
//         />
//         <button onClick={sendData}>Upload</button>
//       </form>
//     </div>
//   );
// }
// export default Test;

import { useState, useEffect } from "react";
import { getCookie } from "./Cookie";
const Test = () => {
  useEffect(() => {
    window.location =
      "https://mercury-t2.phonepe.com/transact/pg?token=YTVmZDMwMDVmMDViNDZjZGEzMzFmOWFjMWQ2MWI4NDE2ZWQ0OTk0NzM2NGM2YmI2NTNjZjkwMDQ4ZjgwY2ZjMzQ0NDllNjdlYjE3YTgyMmFhNjc2ZTU2YTo5MWYzMDNkYTY3OTJmYjBmZGM2YmE4MDdhYzgwNWVmZg";
  });
  return <div>hello</div>;
};
export default Test;
