import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useState, useEffect, useContext } from "react";
import { myContext } from "./MyContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getCookie } from "./Cookie";
import { path } from "./path";
import { bankinfo } from "./bankinfo";
const cookie = getCookie();
const CreditCardPay = () => {
  const [formData, setFormData] = useState({ bank: "" });
  const context = useContext(myContext);
  const [transID, setTransID] = useState("");
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState();
  const [firstPart, setFirstPart] = useState(true);
  const [bank, setBank] = useState();
  const [otp, setOtp] = useState({ value: "", time: "", verify: false });
  const [clear, setClear] = useState();
  const cookie = getCookie();
  const { id } = useParams();
  const [showCash, setShowCash] = useState(false);
  const [cashback, setcashback] = useState("");
  const [cashAvailable, setCashbackAvailable] = useState(0);
  const [cashbackUsed, setCashbackUsed] = useState(0);
  const [wantCashback, setWantCashback] = useState(false);
  const [showOperator, setShowOperator] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [showManual, setShowManual] = useState(0);

  useEffect(() => {
    setBankData(bankinfo.sort((a) => a.bankName));
    if (id == 101) {
      alert("Payment Successful");
    } else if (id == 102) {
      alert("Payment Unsuccessful");
    }
    axios
      .get("https://api.grewbig.com/index.php/item/bankDetails")
      .then((d) => {
        setBank(d.data);
      });
  }, []);
  useEffect(() => {
    if (!cookie.id) {
      navigate("/");
    }
    var data = new FormData();
    data.append("id", cookie.id);
    axios
      .post("https://api.grewbig.com/index.php/item/userDetails", data)
      .then((d) => {
        setUserDetails(d.data);
        axios
          .post("https://api.grewbig.com/index.php/item/creditcashback", data)
          .then((d) => {
            console.log(d.data[0].cashbackearn - d.data[0].cashbackuse);
            setCashbackAvailable(
              d.data[0].cashbackearn - d.data[0].cashbackuse
            );
          });
      });
  }, []);
  function submitData(e) {
    e.preventDefault();
    if (!(formData.bank && formData.amount)) {
      alert("Please complete all Form fields");
    } else if (!formData.credit.length == 16) {
      alert("Please Enter a 16 Digit Credit Card Number");
    } else if (!document.getElementById("customCheckb1").checked) {
      alert("Please Accept the Terms and Conditions before proceeding");
    } else {
      setFirstPart(false);
    }
  }
  function payment(e) {
    e.preventDefault();
    var amount = formData.amount;
    var cashbackuse = 0;
    var cashbackearn = Math.floor((2 / 100) * formData.amount);
    console.log(wantCashback);
    if (wantCashback) {
      amount = formData.amount - Math.min(cashAvailable, formData.amount);
      cashbackuse = Math.min(formData.amount, cashAvailable);
    }
    var sendData = new FormData();
    sendData.append("name", userDetails.name);
    sendData.append("email", userDetails.email);
    sendData.append("cashback", cashback);
    sendData.append("amount", amount);
    sendData.append("actualAmount", formData.amount);
    sendData.append("bank", formData.bank);
    sendData.append("creditCardNo", formData.credit);
    sendData.append("cashbackuse", cashbackuse);
    sendData.append("cashbackearn", cashbackearn);
    sendData.append("mobile", userDetails.mobile);
    sendData.append("id", userDetails.id);
    alert('Something Went Wrong');
    // axios
    //   .post(
    //     "https://api.grewbig.com/index.php/paymentcard/paymentinitiate",
    //     sendData
    //   )
    //   .then((d) => {
    //     console.log(d);
    //     window.location = d.data.url;
    //   })
    //   .catch((e) => console.log(e));
  }
  function paymentManual() {
    if (!transID) {
      alert("Enter Transaction ID to Complete the Payment");
    } else {
      var amount = formData.amount;
      var cashbackuse = 0;
      var cashbackearn = Math.floor((2 / 100) * formData.amount);
      console.log(wantCashback);
      if (wantCashback) {
        amount = formData.amount - Math.min(cashAvailable, formData.amount);
        cashbackuse = Math.min(formData.amount, cashAvailable);
      }
      var sendData = new FormData();
      sendData.append("name", userDetails.name);
      sendData.append("email", userDetails.email);
      sendData.append("cashback", cashback);
      sendData.append("amount", amount);
      sendData.append("actualAmount", formData.amount);
      sendData.append("bank", formData.bank);
      sendData.append("creditCardNo", formData.credit);
      sendData.append("cashbackuse", cashbackuse);
      sendData.append("cashbackearn", cashbackearn);
      sendData.append("mobile", userDetails.mobile);
      sendData.append("id", userDetails.id);
      sendData.append("transID", transID);

      axios
        .post(
          "https://api.grewbig.com/index.php/paymentcard/paymentinitiateManual",
          sendData
        )
        .then((d) => {
          console.log(d);
          window.location = "https://grewbig.com/credittransactions";
        })
        .catch((e) => console.log(e));
    }
  }
  return (
    <div>
      <Header />
      {showManual ? (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.6)",
            height: "100%",
            width: "100%",
            zIndex: "999",
            padding: "20px",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              backgroundColor: "white",
              transform: "translate(-50%,-50%)",
              padding: "20px",
              width: "90%",
            }}
          >
            <h3 style={{ textAlign: "center" }}>
              Payment Amount: <br />
              Rs. {formData.amount}
            </h3>
            <div className="body-payment">
              <p
                style={{
                  backgroundColor: "#fff2c8",
                  margin: "10px",
                  fontSize: "12px",
                  lineHeight: "16px",
                  padding: "5px",
                  color: "#927a30",
                }}
              >
                Option 1: Please Screenshot the page or Download QR Code to Pay
                using any UPI App.
              </p>
              <div>
                <div>
                  <img
                    src="./assets/img/qr.jpg"
                    alt="QR Code"
                    style={{ maxWidth: "180px" }}
                  />
                </div>
                <a
                  href="./assets/img/qr.jpg"
                  class="btn btn-sm"
                  download
                  style={{
                    backgroundColor: "#02a4ba",
                    borderRadius: "0px",
                    color: "white",
                    marginTop: "10px",
                  }}
                >
                  Download QR Code
                </a>

                <div style={{ marginTop: "10px" }}>
                  <input
                    type="text"
                    disabled
                    value="grewbig97@yesbank"
                    style={{ maxWidth: "164px" }}
                  />
                  <span
                    className="btn btn-md"
                    style={{
                      border: "0.5px solid black",
                      borderRadius: "0px",
                      height: "31.59px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText("grewbig97@yesbank");
                    }}
                  >
                    Copy
                  </span>
                </div>
                {/* <p>UPI App Payment Quick Link</p>
                <a
                  href="phonepe://pay?pa=grewbig97@yesbank&pn=GrewBig&cu=INR"
                  className="btn btn-md"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "2px",
                    border: "1px solid black",
                    color: "#5f259f",
                    fontWeight: "bolder",
                    fontSize: "16px",
                  }}
                >
                  PhonePe
                </a>
                <a
                  href="tez://upi/pay?pa=grewbig97@yesbank&pn=GrewBig&cu=INR"
                  className="btn btn-md"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "2px",
                    border: "1px solid black",
                    color: "#5d6062",
                    fontWeight: "bolder",
                    fontSize: "16px",
                    marginLeft: "10px",
                    minWidth: "102.61px",
                  }}
                >
                  Gpay
                </a> */}
                <p
                  style={{
                    backgroundColor: "#fff2c8",
                    margin: "10px",
                    fontSize: "12px",
                    lineHeight: "16px",
                    padding: "5px",
                    color: "#927a30",
                  }}
                >
                  Option 2: You can also pay directly to our Bank Account.
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontSize: "13px",
                    textAlign: "left",
                    paddingLeft: " 10px",
                  }}
                >
                  <p style={{ lineHeight: "11px" }}>
                    Name: Grewbig Recharge Private Limited
                  </p>
                  <p style={{ lineHeight: "11px" }}>
                    Account Number: 072763200000820
                  </p>
                  <p style={{ lineHeight: "11px" }}>IFSC Code: YESB0000727</p>
                </div>
              </div>
              <div
                className="form-group basic"
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  marginTop: "10px",
                }}
              >
                <div className="input-wrapper">
                  <label className="label" for="amount">
                    Enter UTR/Transaction ID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="transId"
                    required
                    placeholder="Enter UTR/Transaction ID"
                    value={transID}
                    onBlur={(e) => {
                      if (!/^[0-9a-zA-Z]*$/g.test(e.target.value.trim())) {
                        e.target.style.borderColor = "red";
                        e.target.nextElementSibling.style.display = "block";
                        setTransID("");
                      } else {
                        e.target.style.borderColor = "#DCDCE9";
                        e.target.nextElementSibling.style.display = "none";
                      }
                    }}
                    onChange={(e) => {
                      setTransID(e.target.value);
                    }}
                  />

                  <div style={{ display: "none", color: "red" }}>
                    Transaction ID Should Contains Only Numbers and alphabets
                  </div>
                </div>
              </div>
              <p style={{ color: "red", lineHeight: "16px", fontSize: "12px" }}>
                Transaction will not be completed if UTR/Trans. ID is incorrect
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  paymentManual();
                }}
                className="btn btn-sm btn-success"
              >
                Submit Payment
              </button>
              <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowManual(0);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div
        className="modal fade show dialogbox"
        id="termsModal"
        data-bs-backdrop="static"
        tabindex="-1"
        role="dialog"
        style={{ backgroundColor: "rgba(1,1,1,0.5)" }}
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "500px",
              padding: "20px",
            }}
          >
            <h2>Terms And Conditions</h2>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "10px",
                overflow: "auto",
              }}
            >
              <p>
                Welcome to Grewbig Recharge, Dear Customer. Here are the terms
                and conditions for Credit Card Bill Payment and Cash Back:
              </p>
              <p>
                - If you pay your credit card bill through Grewbig Recharge, our
                company rewards you with a 2% cashback on paying your credit
                card bill.
              </p>
              <p>- Your credit card bill will be updated within 24 hours.</p>
              <p>
                - Yes, the rewards can be redeemed on your next credit card bill
                payment.
              </p>
              <p>
                - The cashback will be credited to your Grewbig Recharge
                Application Reward Wallet within 72 hours.
              </p>
              <p>
                - These rewards can be availed by any citizen of India by simply
                paying the credit card bill.
              </p>
              <p>
                - You can use these rewards only on the Grewbig Recharge
                application. There are no restrictions if you want to enjoy the
                rewards by paying your credit card bill.
              </p>
              <p>
                - If you're happy with your life, then pay your credit card bill
                through Grewbig Recharge and get cashback. It's very easy:
              </p>
              <ol>
                <li>First Step: Click on Credit Card Bill Payment option.</li>
                <li>Second Step: Enter Credit Card Number.</li>
                <li>Third Step: Select Bank Name and Credit Card.</li>
                <li>Fourth Step: Enter Payment Amount.</li>
                <li>Fifth Step: Select Payment Mode.</li>
                <li>Sixth Step: Receive Bill Challan Successfully.</li>
              </ol>
              <p>
                For more details, you can call our toll-free number 18005721577,
                email us, chat with us live, or talk on WhatsApp. Our services
                are open 24 hours.
              </p>
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: "black",
                fontSize: "15px",
                marginBottom: "20px",
              }}
              align="center"
            >
              <button
                className="btn btn-md btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("termsModal").style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {!firstPart ? (
        <div>
          {/* Dialog Basic -*/}
          <div
            className="modal fade dialogbox"
            id="DialogBasic"
            data-bs-backdrop="static"
            tabindex="-1"
            role="dialog"
          >
            <div className="modal-dialog" role="document">
              <div
                className="modal-content"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src="./assets/img/check_mark.png"
                  class="img-logo"
                  alt="GrewBig"
                />
                <div
                  style={{
                    fontWeight: "bold",
                    color: "green",
                    fontSize: "20px",
                  }}
                >
                  Payment Successful{" "}
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    fontSize: "15px",
                    marginBottom: "20px",
                  }}
                  align="center"
                >
                  Hurray ! You have saved 50% on your Yearly Recharge.
                </div>
              </div>
            </div>
          </div>
          {/* * Dialog Basic -*/}
          {/* Dialog Basic -*/}
          {/* <div
            className="modal fade dialogbox"
            id="DialogBasic"
            data-bs-backdrop="static"
            tabindex="-1"
            role="dialog"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Proceed for Payment</h5>
                </div>
                <div className="modal-body">Are you sure?</div>
                <div className="modal-footer">
                  <div className="btn-inline">
                    <a
                      href="#"
                      className="btn btn-text-secondary"
                      data-bs-dismiss="modal"
                    >
                      CANCEL
                    </a>
                    <a
                      href="#"
                      className="btn btn-text-primary"
                      data-bs-dismiss="modal"
                    >
                      Proceed
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* * Dialog Basic -*/}

          {/* App Capsule -*/}
          <div id="appCapsule" className=" bg-white">
            <div className="section mt-2 mb-2">
              <ul className="listview flush transparent simple-listview no-space mt-3">
                <li>
                  <strong>Credit Card Number</strong>
                  <span className="text-success">
                    {formData.credit.substr(0, 4) +
                      " " +
                      formData.credit.substr(4, 4) +
                      " " +
                      formData.credit.substr(8, 4) +
                      " " +
                      formData.credit.substr(12, 4)}
                  </span>
                </li>
                <li>
                  <strong>Bank</strong>
                  <span>{formData.bank}</span>
                </li>
                <li>
                  <strong>Payment Amount</strong>
                  <span>{formData.amount}</span>
                </li>
                <li>
                  <strong>Cashback Earned on this recharge</strong>
                  <span>{formData.amount * (2 / 100)}</span>
                </li>
                <li>
                  <strong>Cashback Used in this recharge</strong>
                  <span>
                    {wantCashback
                      ? Math.min(formData.amount, cashAvailable)
                      : 0}
                  </span>
                </li>
                <li>
                  <strong>Amount To be Pay</strong>
                  <span>
                    {wantCashback
                      ? formData.amount -
                        Math.min(formData.amount, cashAvailable)
                      : formData.amount}
                  </span>
                </li>
              </ul>
              <div className="listed-detail mt-3">
                <h3 className="text-center mt-2">Proceed to Payment</h3>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="icon-wrapper"
                    // data-bs-toggle="modal"
                    // data-bs-target="#DialogBasic"
                    onClick={payment}
                  >
                    <div style={{ cursor: "pointer" }}>
                      <span className="btn btn-md btn-success">
                        Online Payment
                      </span>
                    </div>
                  </div>
                  {/* <div
                    className="icon-wrapper"
                    // data-bs-toggle="modal"
                    // data-bs-target="#DialogBasic"
                    onClick={() => {
                      setShowManual(1);
                    }}
                  >
                    <div style={{ cursor: "pointer", marginLeft: "10px" }}>
                      <span className="btn btn-md btn-success">
                        Proceed Payment
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* * App Capsule -*/}
        </div>
      ) : (
        <div id="appCapsule">
          <div className="section mb-5 p-2">
            <form action="index.html">
              <div className="card">
                <div
                  className="card-body"
                  style={{
                    backgroundImage: "url('assets/img/bg2.jpg')",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" for="rechargeNumber">
                        Enter Credit Card Number
                      </label>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <div className="card-new">
                          <div>
                            <div
                              class="balance card-custom"
                              style={{ marginBottom: "43px" }}
                            >
                              <h1
                                style={{
                                  marginTop: "15px",
                                  color: "white",
                                  fontSize: "15px",
                                }}
                              >
                                Enter Credit Card Number
                              </h1>
                            </div>
                            <div class="in">
                              <div
                                class="card-number"
                                style={{
                                  fontSize: "18px",
                                  marginTop: "60px",
                                  zIndex: 100,
                                }}
                              >
                                <input
                                  type="text"
                                  style={{ width: "92%", paddingLeft: "10px" }}
                                  maxLength="16"
                                  placeholder="xxxx-xxxx-xxxx-xxxx"
                                  id="credit"
                                  value={formData["credit"]}
                                  onBlur={(e) => {
                                    if (
                                      !/^\d{16}$/g.test(e.target.value.trim())
                                    ) {
                                      e.target.style.borderColor = "red";
                                      document.getElementById(
                                        "cardErr"
                                      ).style.display = "block";
                                      setFormData({
                                        ...formData,
                                        ...{ [e.target.id]: "" },
                                      });
                                    } else {
                                      document.getElementById(
                                        "credit"
                                      ).style.borderColor = "#DCDCE9";
                                      document.getElementById(
                                        "cardErr"
                                      ).style.display = "none";
                                    }
                                  }}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      ...{ [e.target.id]: e.target.value },
                                    });
                                  }}
                                />
                              </div>
                              <div class="bottom" style={{ marginTop: "6px" }}>
                                <div
                                  class="card-expiry"
                                  style={{
                                    fontSize: "10px",
                                  }}
                                ></div>
                                <div
                                  class="card-ccv"
                                  style={{ marginLeft: "17px" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="cardErr"
                      style={{ display: "none", color: "red" }}
                      align="center"
                    >
                      Please Enter 16 Digits Valid Credit Card No
                    </div>
                  </div>

                  {/* <div className="form-group basic">
                    <div className="input-wrapper register-select-custom">
                      <label className="label" for="operator">
                        Select Bank
                      </label>
                      <select
                        className="form-select"
                        id="bank"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            ...{ [e.target.id]: e.target.value },
                          });
                        }}
                      >
                        <option selected value="">
                          Select Bank
                        </option>
                        {bank
                          ? bank.map((val) => {
                              return (
                                <option value={val.bank} key={val.id}>
                                  {val.bank}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                  </div> */}

                  <div className="form-group basic">
                    <div className="input-wrapper register-select-custom">
                      <label className="label" for="operator">
                        Select Bank
                      </label>
                      <input
                        className="form-select"
                        id="bank"
                        onClick={() => {
                          setShowOperator(!showOperator);
                        }}
                        style={{ cursor: "pointer" }}
                        placeholder="Select Bank"
                        onChange={(e) => {
                          setBankData(
                            bankinfo.filter((val) => {
                              if (e.target.value == "") {
                                return val;
                              } else if (
                                val.bankName
                                  .toLowerCase()
                                  .indexOf(e.target.value.toLowerCase()) != -1
                              ) {
                                return val;
                              }
                            })
                          );
                        }}
                      />
                      {formData["bank"].length ? (
                        <img
                          src={"./assets/img/bank/" + formData["bank"] + ".png"}
                          class="img-logo"
                          alt="GrewBig"
                          style={{
                            width: "40px",
                            position: "absolute",
                            top: "7px",
                            right: "37px",
                          }}
                        />
                      ) : (
                        ""
                      )}
                      {showOperator ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            maxHeight: "220px",
                            overflow: "auto",
                            scrollbarWidth: "thin",
                          }}
                          class="operator-select"
                        >
                          {bankData.length
                            ? bankData.map((val) => {
                                return (
                                  <span
                                    onClick={() => {
                                      setFormData({
                                        ...formData,
                                        ...{ bank: [val.bankName] },
                                      });
                                      document.getElementById("bank").value =
                                        val.bankName;
                                      setShowOperator(!showOperator);
                                    }}
                                  >
                                    <img
                                      src={
                                        "./assets/img/bank/" +
                                        val.bankName +
                                        ".png"
                                      }
                                      class="img-logo"
                                      alt="GrewBig"
                                      style={{ width: "40px" }}
                                    />
                                    {val.bankName}
                                  </span>
                                );
                              })
                            : ""}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" for="amount">
                        Enter Pay Amount
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="amount"
                        required
                        placeholder="Enter Pay Amount"
                        value={formData["amount"]}
                        onBlur={(e) => {
                          if (!/^[1-9]\d*$/g.test(e.target.value.trim())) {
                            e.target.style.borderColor = "red";
                            e.target.nextElementSibling.style.display = "block";
                            setFormData({
                              ...formData,
                              ...{ [e.target.id]: "" },
                            });
                            setShowCash(!showCash);
                          } else {
                            e.target.style.borderColor = "#DCDCE9";
                            e.target.nextElementSibling.style.display = "none";
                            setShowCash(!showCash);
                          }
                        }}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            ...{ [e.target.id]: e.target.value },
                          });
                        }}
                      />

                      <div style={{ display: "none", color: "red" }}>
                        Amount Should Contains Only Numbers
                      </div>
                      <div
                        style={{ display: "none", color: "green" }}
                        id="cashBack"
                      ></div>
                    </div>
                  </div>
                  {formData.amount ? (
                    cashAvailable > 0 ? (
                      <div className="custom-control custom-checkbox mt-2 mb-1">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="cashbackavail"
                            onClick={() => {
                              setWantCashback(!wantCashback);
                            }}
                          />
                          <label
                            className="form-check-label"
                            for="cashbackavail"
                          >
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              {" "}
                              Use Cashback of Rs.{" "}
                              {Math.min(cashAvailable, formData.amount)}
                            </span>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <span style={{ color: "black" }}>
                        Cashback Balance: <b>Rs. {cashAvailable}</b>
                      </span>
                    )
                  ) : (
                    ""
                  )}
                  <div className="custom-control custom-checkbox mt-2 mb-1">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheckb1"
                      />
                      <label className="form-check-label" for="customCheckb1">
                        I agree{" "}
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            document.getElementById(
                              "termsModal"
                            ).style.display = "block";
                          }}
                          style={{ color: "green" }}
                        >
                          terms and conditions
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="form-button-group transparent"
                style={{ position: "static" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-lg"
                  onClick={submitData}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* App Bottom Menu */}
      <Footer />
      {/* * App Bottom Menu */}

      {/* App Sidebar */}
      <Sidebar />
      {/* * App Sidebar */}
    </div>
  );
};

export default CreditCardPay;
