import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useContext, useState, useEffect } from "react";
import { myContext } from "./MyContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { getCookie } from "./Cookie";
import { path } from "./path";
const Home = () => {
  const context = useContext(myContext);
  const navigate = useNavigate();
  const [cardDetails, setCardDetails] = useState();
  const cookie = getCookie();
  const { id } = useParams();
  const [p, setP] = useState();
  const [cardAmount, setCardAmount] = useState();
  const [amountPercentage, setAmountPercentage] = useState("0%");
  const [load, setLoad] = useState(false);
  const [useAmount, setUseAmount] = useState(0);
  const [userDetails, setUserDetails] = useState();
  const [inactive, setInactive] = useState(false);
  const [cashbackAvailable, setCashbackAvailable] = useState(0);
  const [cardCashbackAvailable, setcardCashbackAvailable] = useState(0);

  useEffect(() => {
    var carouselID = Array.from(
      document.getElementById("carousel-live").children
    );
    var index = 0;
    setInterval(() => {
      carouselID[index].classList.remove("active");
      if (index != carouselID.length - 1) {
        carouselID[++index].classList.add("active");
      } else {
        index = 0;
        carouselID[index].classList.add("active");
      }
    }, 3000);
  }, []);
  useEffect(() => {
    if (!cookie.id) {
      navigate("/");
    }

    var data = new FormData();
    data.append("id", cookie.id);

    axios
      .post("https://api.grewbig.com/index.php/item/userDetails", data)
      .then((d) => {
        setUserDetails(d.data);
      })
      .catch((e) => console.log(e));
    axios
      .post("https://api.grewbig.com/index.php/item/cardDetails", data)
      .then((d) => {
        setCardDetails(d.data);
      });

    axios
      .post("https://api.grewbig.com/index.php/item/rechargecashback", data)
      .then((d) => {
        setCashbackAvailable(d.data[0].cashbackearn - d.data[0].cashbackuse);
        var percash =
          ((d.data[0].cashbackearn - d.data[0].cashbackuse) / 10000) * 100;

        setAmountPercentage(percash + "%");
      });
    axios
      .post("https://api.grewbig.com/index.php/item/cardcashback", data)
      .then((d) => {
        console.log(d);
        setcardCashbackAvailable(
          d.data[0].cashbackearn - d.data[0].cashbackuse
        );
      });
  }, []);

  return (
    <div>
      {/* {load ? (
        <div id="loader">
          <img
            src={path + "/assets/img/logo-grewbig.png"}
            alt="icon"
            class="loading-icon"
          />
        </div>
      ) : (
        ""
      )} */}

      <div
        className="modal show dialogbox"
        id="DialogBasic"
        data-bs-backdrop="static"
        tabindex="-1"
        role="dialog"
        style={{ display: "none", background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            {p == 1 || p == 0 ? (
              <img
                src={path + "/assets/img/check_mark.png"}
                class="img-logo"
                alt="GrewBig"
              />
            ) : (
              <img
                src={path + "/assets/img/error_mark.png"}
                class="img-logo"
                alt="GrewBig"
              />
            )}

            <div
              style={{
                ...{ fontWeight: "bold", color: "", fontSize: "20px" },
                ...(p == 1 ? { color: "green" } : { color: "red" }),
              }}
            >
              {p == 1
                ? "Payment Successful"
                : p == 2
                ? "Payment Unsuccessful"
                : "Payment on Hold"}
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: "black",
                fontSize: "15px",
                marginBottom: "20px",
              }}
              align="center"
            >
              {p == 1
                ? "Hurray ! You have saved 50% on your Yearly Recharge."
                : p == 2
                ? "Please Try Again for Payment"
                : "Please Wait for Some Time"}
              <br />
              <button
                className="btn btn-md btn-primary"
                style={{ marginTop: "8px" }}
                onClick={(e) => {
                  e.preventDefault();
                  document.querySelector("#DialogBasic").style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* App Header */}
      <Header />
      {/* * App Header */}

      {/* App Capsule */}
      <div id="appCapsule">
        {/* Wallet Card */}
        <div className="section wallet-card-section pt-1">
          <div
            className="wallet-card"
            style={{
              backgroundImage: "url('assets/img/bg2.jpg')",
              backgroundSize: "cover",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div className="card-new1">
                <div>
                  <div
                    class="balance card-custom"
                    style={{ marginBottom: "43px" }}
                  >
                    <h1
                      style={{
                        marginTop: "15px",
                        color: "white",
                        fontSize: "20px",
                      }}
                    >
                      CASHBACK CARD
                    </h1>
                  </div>
                  <div class="in">
                    <div
                      class="card-number"
                      style={{ fontSize: "157%", marginTop: "47px" }}
                    >
                      {cardDetails ? (
                        <span>
                          {" "}
                          {cardDetails.cardNumber.substr(0, 4)}&nbsp;{" "}
                          {cardDetails.cardNumber.substr(3, 4)}&nbsp;{" "}
                          {cardDetails.cardNumber.substr(7, 4)}&nbsp;{" "}
                          {cardDetails.cardNumber.substr(11, 4)}&nbsp;{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="bottom" style={{ marginTop: "6px" }}>
                      <div
                        class="card-expiry"
                        style={{
                          fontSize: "10px",
                        }}
                      >
                        12 / 25
                      </div>
                      <div class="card-ccv">
                        <span class="label">
                          {userDetails ? userDetails.name : ""}
                        </span>
                      </div>
                    </div>
                    <div
                      className="card-status"
                      style={{
                        textAlign: "right",
                        marginTop: "-30px",
                        marginRight: "24px",
                      }}
                    >
                      <span class="badge bg-success">Active</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              class="card-block mb-2"
              style={{
                position: "static",
                width: "100%",
                height: "201px",
                background: "none",
                borderRadius: "25px",
              }}
            >
              <div
                class="card-main"
                style={{
                  position: "relative",
                  minWidth: "100%",
                  display: "block",
                  height: "100%",
                  backgroundImage: "url('assets/img/bgcard.jpg')",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              >
                
              </div>
            </div> */}
            {false ? (
              ""
            ) : true ? (
              <div
                style={{
                  backgroundColor: "white",
                  padding: "15px",
                  borderRadius: "20px",
                }}
              >
                {/* Balance */}
                <div className="balance" style={{ marginBottom: "0px" }}>
                  <div className="right" align="center">
                    <span className="title" style={{ fontWeight: "bold" }}>
                      Recharge Cashback Bal.
                    </span>
                    <h3 className="limit-style">Rs. {cashbackAvailable}</h3>
                  </div>
                  <div className="left" align="center">
                    <span className="title" style={{ fontWeight: "bold" }}>
                      Maximum Limit
                    </span>
                    <h3
                      className="limit-style"
                      style={{ backgroundColor: "green" }}
                    >
                      Rs. 10000
                    </h3>
                  </div>
                </div>

                {/* * Balance */}
                <div className="card">
                  <div class="progress" style={{ backgroundColor: "red" }}>
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: amountPercentage }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div
                  align="center"
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "green",
                  }}
                >
                  <br />
                  <div>Card Cashback Available: {cardCashbackAvailable}</div>
                </div>
              </div>
            ) : inactive == true ? (
              <div className="text-center" style={{ marginBottom: "20px" }}>
                <Link to="/emidetails" class="btn btn-danger">
                  Pay EMI to Activate Card
                </Link>
              </div>
            ) : (
              <div className="text-center" style={{ marginBottom: "20px" }}>
                <Link to="/activatecard" class="btn btn-success">
                  Activate Card
                </Link>
              </div>
            )}
            {/* Wallet Footer */}
            <div className="wallet-footer" style={{ display: "block" }}>
              <div
                id="carouselExampleSlidesOnly"
                class="carousel slide"
                data-ride="carousel"
              ></div>
            </div>
            {/* * Wallet Footer */}
          </div>
        </div>
        {/* Wallet Card */}

        {/* Transactions */}
        <div className="section " style={{ marginTop: "12px" }}>
          <div className="section-heading">
            <div
              class="carousel-inner"
              id="carousel-live"
              style={{ animation: "linear" }}
            >
              <div class="carousel-item active">
                <img
                  src={path + "/assets/img/one.png"}
                  class="d-block w-100"
                  alt="..."
                  style={{ width: "280px", height: "100px" }}
                />
              </div>
              <div class="carousel-item " style={{ animation: "linear" }}>
                <img
                  src={path + "/assets/img/two.png"}
                  class="d-block w-100"
                  alt="..."
                  style={{ width: "280px", height: "100px" }}
                />
              </div>
              <div class="carousel-item" style={{ animation: "linear" }}>
                <img
                  src={path + "/assets/img/three.png"}
                  class="d-block w-100"
                  alt="..."
                  style={{ width: "280px", height: "100px" }}
                />
              </div>
              <div class="carousel-item" style={{ animation: "linear" }}>
                <img
                  src={path + "/assets/img/four.png"}
                  class="d-block w-100"
                  alt="..."
                  style={{ width: "280px", height: "100px" }}
                />
              </div>
              <div class="carousel-item" style={{ animation: "linear" }}>
                <img
                  src={path + "/assets/img/five.png"}
                  class="d-block w-100"
                  alt="..."
                  style={{ width: "280px", height: "100px" }}
                />
              </div>
              <div class="carousel-item" style={{ animation: "linear" }}>
                <img
                  src={path + "/assets/img/six.png"}
                  class="d-block w-100"
                  alt="..."
                  style={{ width: "280px", height: "100px" }}
                />
              </div>
              <div class="carousel-item" style={{ animation: "linear" }}>
                <img
                  src={path + "/assets/img/seven.png"}
                  class="d-block w-100"
                  alt="..."
                  style={{ width: "280px", height: "100px" }}
                />
              </div>
              <div class="carousel-item" style={{ animation: "linear" }}>
                <img
                  src={path + "/assets/img/eight.png"}
                  class="d-block w-100"
                  alt="..."
                  style={{ width: "280px", height: "100px" }}
                />
              </div>
              <div class="carousel-item" style={{ animation: "linear" }}>
                <img
                  src={path + "/assets/img/nine.png"}
                  class="d-block w-100"
                  alt="..."
                  style={{ width: "280px", height: "100px" }}
                />
              </div>
              <div class="carousel-item" style={{ animation: "linear" }}>
                <img
                  src={path + "/assets/img/ten.png"}
                  class="d-block w-100"
                  alt="..."
                  style={{ width: "280px", height: "100px" }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* * Transactions */}
      </div>
      {/* * App Capsule */}

      {/* App Bottom Menu */}
      <Footer />
      {/* * App Bottom Menu */}

      {/* App Sidebar */}
      <Sidebar />
      {/* * App Sidebar */}
    </div>
  );
};

export default Home;
