import { useEffect, useState, useContext } from "react";
import axios from "axios";
import SidebarA from "./SidebarA";
import { path } from "../path";
import { myContext } from "../MyContext";

const HeaderA = () => {
  // useEffect(() => {
  //   console.log("gg");
  //   axios
  //     .post("https://api.grewbig.com/index.php/item/", {
  //       title: "abhishek",
  //       description: "titledescription",
  //     })
  //     .then((d) => {
  //       console.log(d.data);
  //     })
  //     .catch((e) => console.log(e));
  // }, []);
  const [load, setLoad] = useState(false);
  const context = useContext(myContext);

  useEffect(() => {
    setLoad(true);
    // var loader = document.getElementById("loader");
    setTimeout(() => {
      // loader.setAttribute(
      //   "style",
      //   "pointer-events: none; opacity: 0; transition: 0.2s ease-in-out;"
      // );
      setTimeout(() => {
        setLoad(false);
      }, 1000);
    }, 450);
  }, []);
  return (
    <div>
      {load ? (
        <div id="loader">
          <img
            src={path + "/assets/img/logo-grewbig.png"}
            alt="icon"
            class="loading-icon"
          />
        </div>
      ) : (
        ""
      )}
      <SidebarA />
      <div className="appHeader bg-primary text-light">
        <div className="left">
          {/* <a
            href="#"
            className="headerButton"
            data-bs-toggle="modal"
            data-bs-target="#sidebarPanel"
          > */}
          <ion-icon
            name="menu-outline"
            onClick={() => {
              context.setSideBar(!context.sideBar);
            }}
          ></ion-icon>
          {/* </a> */}
        </div>
        <div className="pageTitle">
          <img
            src={path + "/assets/img/logo-grewbig.png"}
            alt="logo"
            className="logo"
            style={{ width: "70px", maxHeight: "70px" }}
          />
          GrewBig
        </div>
        {/* <div className="right">
          <a href="app-notifications.html" className="headerButton">
            <ion-icon className="icon" name="notifications-outline"></ion-icon>
            <span className="badge badge-danger">4</span>
          </a>
          <a href="app-settings.html" className="headerButton">
            <img
              src="assets/img/sample/avatar/avatar1.jpg"
              alt="image"
              className="imaged w32"
            />
            <span className="badge badge-danger">6</span>
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default HeaderA;
