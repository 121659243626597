import { useContext, useState, useEffect } from "react";
import { myContext } from "./MyContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getCookie } from "./Cookie";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [userDetails, setUserDetails] = useState();
  const context = useContext(myContext);
  const navigate = useNavigate();
  const cookie = getCookie();
  useEffect(() => {
    if (!cookie.id) {
      navigate("/");
    }
    var data = new FormData();
    data.append("id", cookie.id);
    axios
      .post("https://api.grewbig.com/index.php/item/userDetails", data)
      .then((d) => {
        setUserDetails(d.data);
      });
  }, []);
  useEffect(() => {
    if (context.sideBar) {
      console.log("sidebar second");
      document.getElementById("sidebarPanel").classList.add("show");
      document.getElementById("sidebarPanel").style.display = "block";
    } else {
      document.getElementById("sidebarPanel").classList.remove("show");
      document.getElementById("sidebarPanel").style.display = "none";
    }
  }, [context.sideBar]);
  return (
    <div
      className="modal fade panelbox panelbox-left show"
      id="sidebarPanel"
      tabindex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body p-0">
            {/* profile box */}
            <div className="profileBox pt-2 pb-2">
              <div className="image-wrapper">
                {/* <img
                  src="C:\xampp\htdocs\recharge\application\images\2022-08-10 (1).png"
                  alt="image"
                  className="imaged  w36"
                /> */}
              </div>
              <div className="in">
                <strong>
                  Logged User : {userDetails ? userDetails.name : ""}
                </strong>
                <div className="text-muted"></div>
              </div>
              <a
                href="#"
                className="btn btn-link btn-icon sidebar-close"
                onClick={() => {
                  context.setSideBar(!context.sideBar);
                  document
                    .getElementById("sidebarPanel")
                    .classList.remove("show");
                  document.getElementById("sidebarPanel").style.display =
                    "none";
                }}
              >
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            {/* * profile box */}
            {/* balance */}
            {/* <div className="sidebar-balance">
              <div className="listview-title">Balance</div>
              <div className="in">
                <h1 className="amount">$ 2,562.50</h1>
              </div>
            </div> */}
            {/* * balance */}

            {/* action group */}
            {/* <div className="action-group">
              <a href="index.html" className="action-button">
                <div className="in">
                  <div className="iconbox">
                    <ion-icon name="add-outline"></ion-icon>
                  </div>
                  Deposit
                </div>
              </a>
              <a href="index.html" className="action-button">
                <div className="in">
                  <div className="iconbox">
                    <ion-icon name="arrow-down-outline"></ion-icon>
                  </div>
                  Withdraw
                </div>
              </a>
              <a href="index.html" className="action-button">
                <div className="in">
                  <div className="iconbox">
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                  </div>
                  Send
                </div>
              </a>
              <a href="app-cards.html" className="action-button">
                <div className="in">
                  <div className="iconbox">
                    <ion-icon name="card-outline"></ion-icon>
                  </div>
                  My Cards
                </div>
              </a>
            </div> */}
            {/* * action group */}

            {/* menu */}
            <div className="listview-title mt-1">Menu</div>
            <ul className="listview flush transparent no-line image-listview">
              <li>
                <Link
                  to="/creditcardpay"
                  className="item"
                  onClick={() => {
                    context.setSideBar(!context.sideBar);
                    document
                      .getElementById("sidebarPanel")
                      .classList.remove("show");
                    document.getElementById("sidebarPanel").style.display =
                      "none";
                  }}
                >
                  <div className="icon-box bg-primary">
                    <ion-icon name="card-outline"></ion-icon>
                  </div>
                  <div className="in">Credit Card Bill Pay</div>
                </Link>
              </li>
              <li>
                <Link
                  to="/transactions"
                  className="item"
                  onClick={() => {
                    context.setSideBar(!context.sideBar);
                    document
                      .getElementById("sidebarPanel")
                      .classList.remove("show");
                    document.getElementById("sidebarPanel").style.display =
                      "none";
                  }}
                >
                  <div className="icon-box bg-primary">
                    <ion-icon name="document-text-outline"></ion-icon>
                  </div>
                  <div className="in">Recharge Transactions</div>
                </Link>
              </li>
              <li>
                <Link
                  to="/credittransactions"
                  className="item"
                  onClick={() => {
                    context.setSideBar(!context.sideBar);
                    document
                      .getElementById("sidebarPanel")
                      .classList.remove("show");
                    document.getElementById("sidebarPanel").style.display =
                      "none";
                  }}
                >
                  <div className="icon-box bg-primary">
                    <ion-icon name="document-text-outline"></ion-icon>
                  </div>
                  <div className="in">Credit Transactions</div>
                </Link>
              </li>
              <li>
                <Link
                  to="/activatecard"
                  className="item"
                  onClick={() => {
                    context.setSideBar(!context.sideBar);
                    document
                      .getElementById("sidebarPanel")
                      .classList.remove("show");
                    document.getElementById("sidebarPanel").style.display =
                      "none";
                  }}
                >
                  <div className="icon-box bg-primary">
                    <ion-icon name="phone-portrait-outline"></ion-icon>
                  </div>
                  <div className="in">Recharge Mobile</div>
                </Link>
              </li>

              <li>
                <Link
                  to="/home"
                  className="item"
                  onClick={() => {
                    context.setSideBar(!context.sideBar);
                    document
                      .getElementById("sidebarPanel")
                      .classList.remove("show");
                    document.getElementById("sidebarPanel").style.display =
                      "none";
                  }}
                >
                  <div className="icon-box bg-primary">
                    <ion-icon name="home-outline"></ion-icon>
                  </div>
                  <div className="in">Home</div>
                </Link>
              </li>
              <li>
                <Link
                  to="/customersupport"
                  className="item"
                  onClick={() => {
                    context.setSideBar(!context.sideBar);
                    document
                      .getElementById("sidebarPanel")
                      .classList.remove("show");
                    document.getElementById("sidebarPanel").style.display =
                      "none";
                  }}
                >
                  <div className="icon-box bg-primary">
                    <ion-icon name="home-outline"></ion-icon>
                  </div>
                  <div className="in">Customer Support</div>
                </Link>
              </li>
              <li>
                <a
                  className="item"
                  onClick={(e) => {
                    e.preventDefault();
                    document.cookie =
                      "id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                    navigate("/");
                  }}
                >
                  <div className="icon-box bg-primary">
                    <ion-icon name="log-out-outline"></ion-icon>
                  </div>
                  <div className="in">Logout</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
