import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Compressor from "compressorjs";

const Register = () => {
  const [formData, setFormData] = useState({
    idproof: "",
    mobile: "",
    passcode: "rabbit2020$",
    userType: 1,
  });
  const navigate = useNavigate();
  function generateCard() {
    const data = new FormData();
    data.append("passcode", JSON.stringify({ passcode: "rabbit2020$" }));
    const updatedData = {
      name: formData.name,
      mobile: formData.mobile,
      userType: formData.userType,
      password: formData.password,
      reffer: formData.refer,
    };
    data.append("formData", JSON.stringify(updatedData));
    axios
      .post("https://api.grewbig.com/index.php/item/", data)
      .then((d) => {
        console.log(d);
        window.localStorage.setItem(
          "msg",
          "Please Login to Continue with your Account"
        );
        window.localStorage.setItem("msgTime", new Date().getTime());
        window.localStorage.setItem("msgcount", 1);
        navigate("/");
      })
      .catch((e) => console.log(e));
  }

  function submitData(e) {
    e.preventDefault();
    if (
      !(
        formData.name &&
        formData.name &&
        formData.mobile &&
        formData.password &&
        formData.password2
      )
    ) {
      alert("Please complete all Form fields");
    } else {
      generateCard();
    }
  }
  return (
    <div>
      {/* App Header */}
      <div className="appHeader no-border transparent position-absolute ">
        <div className="left"></div>
        <div className="pageTitle"></div>
        <div className="right">
          <Link to="/" className="headerButton">
            Login
          </Link>
        </div>
      </div>
      {/* * App Header */}

      {/* App Capsule */}
      <div id="appCapsule">
        <div className="section mt-2 text-center">
          <img
            src="./assets/img/logo-grewbig.png"
            class="img-logo"
            alt="GrewBig"
          />
          <h1>Register now</h1>
          <h4>Create an account</h4>
        </div>
        <div className="section mb-5 p-2">
          <form action="index.html">
            <div className="card">
              <div className="card-body">
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="name">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      required
                      placeholder="Enter Your Full Name"
                      value={formData["name"]}
                      onBlur={(e) => {
                        if (!/^[a-z A-Z]+$/g.test(e.target.value.trim())) {
                          e.target.style.borderColor = "red";
                          e.target.nextElementSibling.style.display = "block";
                          setFormData({
                            ...formData,
                            ...{ [e.target.id]: "" },
                          });
                        } else {
                          e.target.style.borderColor = "#DCDCE9";
                          e.target.nextElementSibling.style.display = "none";
                        }
                      }}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          ...{ [e.target.id]: e.target.value },
                        });
                      }}
                    />

                    <div style={{ display: "none", color: "red" }}>
                      The name should contains only alphabets and Spaces and
                      should not be blank.
                    </div>
                  </div>
                </div>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="idproofnumber">
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile"
                      required
                      onBlur={(e) => {
                        var mob = new FormData();
                        mob.append("mobile", e.target.value);
                        var check;
                        axios
                          .post(
                            "https://api.grewbig.com/index.php/item/check",
                            mob
                          )
                          .then((d) => {
                            if (d.data.status == 0) {
                              check = false;
                            } else if (d.data.status == 1) {
                              check = true;
                            }

                            if (!/^[0-9]{10}$/g.test(e.target.value.trim())) {
                              console.log(e.target.value);
                              e.target.style.borderColor = "red";
                              e.target.nextElementSibling.style.display =
                                "block";

                              setFormData({
                                ...formData,
                                ...{ [e.target.id]: "" },
                              });
                            } else {
                              e.target.style.borderColor = "#DCDCE9";

                              e.target.nextElementSibling.style.display =
                                "none";
                            }
                            if (check) {
                              alert("Mobile Number already registered");
                              e.target.style.borderColor = "red";
                              e.target.style.value = "";
                              document
                                .getElementById("reg1")
                                .setAttribute(
                                  "style",
                                  "display:block;color:red"
                                );
                              setFormData({
                                ...formData,
                                ...{ [e.target.id]: "" },
                              });
                            }
                          })
                          .catch((e) => {
                            setFormData({
                              ...formData,
                              ...{ mobile: "" },
                            });
                          });
                      }}
                      placeholder="Enter Mobile Number"
                      value={formData["mobile"]}
                      onChange={(e) => {
                        document
                          .getElementById("reg1")
                          .setAttribute("style", "display:none;color:red");
                        setFormData({
                          ...formData,
                          ...{
                            mobile: e.target.value,
                          },
                        });
                      }}
                    />
                    <div style={{ display: "none", color: "red" }}>
                      Please enter 10 Digits Mobile Number Only and should not
                      be blank.
                    </div>
                    <div id="reg1" style={{ display: "none", color: "red" }}>
                      Mobile Number is already Registered
                    </div>
                  </div>
                </div>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="password1">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      autocomplete="off"
                      required
                      onBlur={(e) => {
                        if (
                          !/^[0-9A-Za-z$@]{6,10}$/g.test(e.target.value.trim())
                        ) {
                          e.target.style.borderColor = "red";
                          e.target.nextElementSibling.style.display = "block";
                          setFormData({
                            ...formData,
                            ...{ [e.target.id]: "" },
                          });
                        } else {
                          e.target.style.borderColor = "#DCDCE9";
                          e.target.nextElementSibling.style.display = "none";
                        }
                      }}
                      placeholder="Your password"
                      value={formData["password"]}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          ...{ [e.target.id]: e.target.value },
                        });
                      }}
                    />
                    <div style={{ display: "none", color: "red" }}>
                      Please enter Password of length 6-10 and containing @,$,
                      Numbers and Alphabets Only and it should not be blank.
                    </div>
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="password2">
                      Password Again
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password2"
                      required
                      onBlur={(e) => {
                        if (e.target.value != formData.password) {
                          e.target.style.borderColor = "red";
                          e.target.nextElementSibling.style.display = "block";
                          setFormData({
                            ...formData,
                            ...{ [e.target.id]: "" },
                          });
                        } else {
                          e.target.style.borderColor = "#DCDCE9";
                          e.target.nextElementSibling.style.display = "none";
                        }
                      }}
                      autocomplete="off"
                      placeholder="Type password again"
                      value={formData["password2"]}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          ...{ [e.target.id]: e.target.value },
                        });
                      }}
                    />
                    <div style={{ display: "none", color: "red" }}>
                      Password not matched with the above
                    </div>
                  </div>
                </div>
                {/* <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="name">
                      Enter Referral Id(Optional)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="refer"
                      required
                      placeholder="Enter Referral ID"
                      value={formData["refer"]}
                      onBlur={(e) => {
                        if (!/^[a-z A-Z 0-9]+$/g.test(" " + e.target.value)) {
                          e.target.style.borderColor = "red";
                          e.target.nextElementSibling.style.display = "block";
                          setFormData({
                            ...formData,
                            ...{ [e.target.id]: "" },
                          });
                        } else {
                          e.target.style.borderColor = "#DCDCE9";
                          e.target.nextElementSibling.style.display = "none";
                        }
                      }}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          ...{ [e.target.id]: e.target.value },
                        });
                      }}
                    />

                    <div style={{ display: "none", color: "red" }}>
                      Reference Code should contain numbers and alphabets only
                    </div>
                  </div>
                </div> */}
                {/* <div className="custom-control custom-checkbox mt-2 mb-1">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckb1"
                      required
                    />
                    <label className="form-check-label" for="customCheckb1">
                      I agree{" "}
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#termsModal"
                      >
                        terms and conditions
                      </a>
                    </label>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="form-button-group transparent">
              <button
                onClick={submitData}
                type="submit"
                id="submitbtn"
                className="btn btn-primary btn-block btn-lg"
              >
                Register
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* * App Capsule */}

      {/* Terms Modal */}
      <div
        className="modal fade modalbox"
        id="termsModal"
        tabindex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Terms and Conditions</h5>
              <a href="#" data-bs-dismiss="modal">
                Close
              </a>
            </div>
            <div className="modal-body">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                fermentum, urna eget finibus fermentum, velit metus maximus
                erat, nec sodales elit justo vitae sapien. Sed fermentum varius
                erat, et dictum lorem. Cras pulvinar vestibulum purus sed
                hendrerit. Praesent et auctor dolor. Ut sed ultrices justo.
                Fusce tortor erat, scelerisque sit amet diam rhoncus, cursus
                dictum lorem. Ut vitae arcu egestas, congue nulla at, gravida
                purus.
              </p>
              <p>
                Donec in justo urna. Fusce pretium quam sed viverra blandit.
                Vivamus a facilisis lectus. Nunc non aliquet nulla. Aenean arcu
                metus, dictum tincidunt lacinia quis, efficitur vitae dui.
                Integer id nisi sit amet leo rutrum placerat in ac tortor. Duis
                sed fermentum mi, ut vulputate ligula.
              </p>
              <p>
                Vivamus eget sodales elit, cursus scelerisque leo. Suspendisse
                lorem leo, sollicitudin egestas interdum sit amet, sollicitudin
                tristique ex. className aptent taciti sociosqu ad litora
                torquent per conubia nostra, per inceptos himenaeos. Phasellus
                id ultricies eros. Praesent vulputate interdum dapibus. Duis
                varius faucibus metus, eget sagittis purus consectetur in.
                Praesent fringilla tristique sapien, et maximus tellus dapibus
                a. Quisque nec magna dapibus sapien iaculis consectetur. Fusce
                in vehicula arcu. Aliquam erat volutpat. className aptent taciti
                sociosqu ad litora torquent per conubia nostra, per inceptos
                himenaeos.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* * Terms Modal */}
    </div>
  );
};

export default Register;
