import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useState, useEffect, useContext } from "react";
import { myContext } from "./MyContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getCookie } from "./Cookie";
const cookie = getCookie();
const ActivateCard = () => {
  const [flag1, setflag1] = useState(false);
  const [showOperator, setShowOperator] = useState(false);
  const [formData, setFormData] = useState({ operator: "" });
  const context = useContext(myContext);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState();
  const [firstPart, setFirstPart] = useState(true);
  const [plan, setPlan] = useState();
  const [otp, setOtp] = useState({ value: "", time: "", verify: false });
  const [clear, setClear] = useState();
  const cookie = getCookie();
  const [showCash, setShowCash] = useState(false);
  const [cashback, setcashback] = useState("");
  const [cashAvailable, setCashbackAvailable] = useState(0);
  const [cashbackUsed, setCashbackUsed] = useState(0);
  const [wantCashback, setWantCashback] = useState(false);

  useEffect(() => {
    var data = new FormData();
    data.append("operator", formData.operator);
    axios
      .post("https://api.grewbig.com/index.php/item/planDetails", data)
      .then((d) => {
        setPlan(d.data);
      });
  }, [formData.operator]);
  useEffect(() => {
    if (!cookie.id) {
      navigate("/");
    }
    var data = new FormData();
    data.append("id", cookie.id);
    axios
      .post("https://api.grewbig.com/index.php/item/userDetails", data)
      .then((d) => {
        setUserDetails(d.data);
        axios
          .post("https://api.grewbig.com/index.php/item/rechargecashback", data)
          .then((d) => {
            setCashbackAvailable(
              d.data[0].cashbackearn - d.data[0].cashbackuse
            );
          });
      });
  }, []);
  function submitData(e) {
    e.preventDefault();
    console.log("hello");
    console.log(formData.rechargeNo);
    if (!(formData.operator && formData.plan && formData.rechargeNo)) {
      alert("Please complete all Form fields");
    } else if (!document.getElementById("customCheckb1").checked) {
      alert("Please Accept the Terms and Conditions before proceeding");
    } else {
      setFirstPart(false);
    }
  }
  function payment(e) {
    e.preventDefault();
    var amount = formData.plan;
    var cashbackuse = 0;
    var cashbackearn = Math.floor((15 / 100) * formData.plan);
    console.log(wantCashback);
    if (wantCashback) {
      amount = formData.plan - Math.min(cashAvailable, formData.plan);
      cashbackuse = Math.min(formData.plan, cashAvailable);
    }
    var sendData = new FormData();
    sendData.append("name", userDetails.name);
    sendData.append("operator", formData.operator);
    sendData.append("plan", formData.plan);
    sendData.append("mobile", userDetails.mobile);
    sendData.append("amount", amount);
    sendData.append("cashbackuse", cashbackuse);
    sendData.append("cashbackearn", cashbackearn);
    sendData.append("rechargeNo", formData.rechargeNo);
    sendData.append("id", userDetails.id);
    alert("Something Went Wrong");
    // axios
    //   .post(
    //     "https://api.grewbig.com/index.php/payment/paymentinitiate",
    //     sendData
    //   )
    //   .then((d) => {
    //     console.log(d);
    //     window.location = d.data.url;
    //   })
    //   .catch((e) => console.log(e));
  }
  return (
    <div>
      <Header />
      {!firstPart ? (
        <div>
          <div
            class="appHeader no-border transparent position-absolute"
            style={{ top: "50px", left: "0px", color: "green" }}
          >
            <div class="left">
              <a
                href="#"
                class="headerButton goBack"
                onClick={() => {
                  setFirstPart(true);
                }}
              >
                <ion-icon name="chevron-back-outline"></ion-icon>Back
              </a>
            </div>
            <div class="pageTitle"></div>
            <div class="right"></div>
          </div>
          {/* Dialog Basic -*/}
          <div
            className="modal fade dialogbox"
            id="DialogBasic"
            data-bs-backdrop="static"
            tabindex="-1"
            role="dialog"
          >
            <div className="modal-dialog" role="document">
              <div
                className="modal-content"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src="./assets/img/check_mark.png"
                  class="img-logo"
                  alt="GrewBig"
                />
                <div
                  style={{
                    fontWeight: "bold",
                    color: "green",
                    fontSize: "20px",
                  }}
                >
                  Payment Successful{" "}
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    fontSize: "15px",
                    marginBottom: "20px",
                  }}
                  align="center"
                >
                  Hurray ! You have saved 50% on your Yearly Recharge.
                </div>
              </div>
            </div>
          </div>
          {/* * Dialog Basic -*/}
          {/* Dialog Basic -*/}
          {/* <div
            className="modal fade dialogbox"
            id="DialogBasic"
            data-bs-backdrop="static"
            tabindex="-1"
            role="dialog"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Proceed for Payment</h5>
                </div>
                <div className="modal-body">Are you sure?</div>
                <div className="modal-footer">
                  <div className="btn-inline">
                    <a
                      href="#"
                      className="btn btn-text-secondary"
                      data-bs-dismiss="modal"
                    >
                      CANCEL
                    </a>
                    <a
                      href="#"
                      className="btn btn-text-primary"
                      data-bs-dismiss="modal"
                    >
                      Proceed
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* * Dialog Basic -*/}

          {/* App Capsule -*/}

          <div id="appCapsule" className=" bg-white">
            <div className="section mt-2 mb-2">
              <ul className="listview flush transparent simple-listview no-space mt-3">
                <li>
                  <strong>Recharge Number</strong>
                  <span className="text-success">{formData.rechargeNo}</span>
                </li>
                <li>
                  <strong>Operator</strong>
                  <span>{formData.operator}</span>
                </li>
                <li>
                  <strong>Plan Details</strong>
                  <span>{formData.plan}</span>
                </li>
                <li>
                  <strong>Amount</strong>
                  <span style={{ maxWidth: "102px" }}>
                    Rs.{formData.plan}/-
                  </span>
                </li>
                <li>
                  <strong>Cashback Earned on this recharge</strong>
                  <span>
                    {userDetails.mobile == formData.rechargeNo
                      ? Math.floor(formData.plan * (15 / 100))
                      : 0}
                  </span>
                </li>
                <li>
                  <strong>Cashback Used in this recharge</strong>
                  <span>
                    {wantCashback ? Math.min(formData.plan, cashAvailable) : 0}
                  </span>
                </li>
                <li>
                  <strong>Amount To be Pay</strong>
                  <span>
                    {wantCashback
                      ? formData.plan - Math.min(formData.plan, cashAvailable)
                      : formData.plan}
                  </span>
                </li>
              </ul>
              <div className="listed-detail mt-3">
                <h3 className="text-center mt-2">Proceed to Payment</h3>
                <div
                  className="icon-wrapper"
                  // data-bs-toggle="modal"
                  // data-bs-target="#DialogBasic"
                  onClick={payment}
                >
                  <div className="iconbox" style={{ cursor: "pointer" }}>
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* * App Capsule -*/}
        </div>
      ) : (
        <div id="appCapsule">
          <div className="section mb-5 p-2">
            <form action="index.html">
              <div className="card">
                <div className="card-body">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" for="rechargeNumber">
                        Recharge Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="rechargeNo"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            ...{ [e.target.id]: e.target.value },
                          });
                        }}
                        value={formData["rechargeNo"]}
                        onBlur={(e) => {
                          if (!/^[0-9]{10}$/g.test(e.target.value.trim())) {
                            e.target.style.borderColor = "red";
                            e.target.nextElementSibling.style.display = "block";
                            setFormData({
                              ...formData,
                              ...{ [e.target.id]: "" },
                            });
                          } else {
                            e.target.style.borderColor = "#DCDCE9";
                            e.target.nextElementSibling.style.display = "none";
                          }
                        }}
                      />
                      <div style={{ display: "none", color: "red" }}>
                        Please enter correct Mobile no Format.
                      </div>
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper register-select-custom">
                      <label className="label" for="operator">
                        Select Operator
                      </label>
                      <input
                        className="form-select"
                        id="operator"
                        onClick={() => {
                          setShowOperator(!showOperator);
                        }}
                        style={{ cursor: "pointer" }}
                        placeholder="Select Mobile Operator"
                        value={formData["operator"]}
                        // onChange={(e) => {
                        //   setFormData({
                        //     ...formData,
                        //     ...{ [e.target.id]: e.target.value },
                        //   });
                        // }}
                      />
                      {formData["operator"].length ? (
                        <img
                          src={
                            "./assets/img/" +
                            formData["operator"].toLowerCase() +
                            ".png"
                          }
                          class="img-logo"
                          alt="GrewBig"
                          style={{
                            width: "40px",
                            position: "absolute",
                            top: "7px",
                            right: "37px",
                          }}
                        />
                      ) : (
                        ""
                      )}
                      {showOperator ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                          class="operator-select"
                        >
                          <span
                            onClick={() => {
                              setFormData({
                                ...formData,
                                ...{ operator: "Airtel" },
                              });
                              setShowOperator(!showOperator);
                            }}
                          >
                            <img
                              src="./assets/img/airtel.png"
                              class="img-logo"
                              alt="GrewBig"
                              style={{ width: "40px" }}
                            />
                            Airtel Prepaid
                          </span>
                          <span
                            onClick={() => {
                              setFormData({
                                ...formData,
                                ...{ operator: "Jio" },
                              });
                              setShowOperator(!showOperator);
                            }}
                          >
                            <img
                              src="./assets/img/jio.png"
                              class="img-logo"
                              alt="GrewBig"
                              style={{ width: "40px" }}
                            />
                            Jio Prepaid
                          </span>
                          <span
                            onClick={() => {
                              setFormData({
                                ...formData,
                                ...{ operator: "BSNL" },
                              });
                              setShowOperator(!showOperator);
                            }}
                          >
                            <img
                              src="./assets/img/bsnl.png"
                              class="img-logo"
                              alt="GrewBig"
                              style={{ width: "40px" }}
                            />
                            Bsnl Prepaid
                          </span>
                          <span
                            onClick={() => {
                              setFormData({
                                ...formData,
                                ...{ operator: "VI" },
                              });
                              setShowOperator(!showOperator);
                            }}
                          >
                            <img
                              src="./assets/img/vi.png"
                              class="img-logo"
                              alt="GrewBig"
                              style={{ width: "40px" }}
                            />
                            VI Prepaid
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <option selected value="">
                          Select Mobile Operator
                        </option>
                        <option value="airtel">
                         
                        </option>
                        <option value="jio">
                          <img
                            src="./assets/img/jio.png"
                            class="img-logo"
                            alt="GrewBig"
                          />
                          Jio Prepaid
                        </option>
                        <option value="bsnl">
                          <img
                            src="./assets/img/bsnl.png"
                            class="img-logo"
                            alt="GrewBig"
                          />
                          BSNL Prepaid
                        </option>
                        <option value="vi">
                          <img
                            src="./assets/img/vi.png"
                            class="img-logo"
                            alt="GrewBig"
                          />
                          VI Prepaid
                        </option>
                      </select> */}
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" for="idproofnumber">
                        Enter Mobile Plan
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="plan"
                        required
                        placeholder="Enter Mobile Plan Ex- 199"
                        onBlur={(e) => {
                          if (!/^[0-9]+$/g.test(e.target.value.trim())) {
                            e.target.style.borderColor = "red";
                            e.target.nextElementSibling.style.display = "block";
                            setFormData({
                              ...formData,
                              ...{ [e.target.id]: "" },
                            });
                          } else {
                            setflag1(true);

                            e.target.style.borderColor = "#DCDCE9";
                            e.target.nextElementSibling.style.display = "none";
                          }
                        }}
                        value={formData["plan"]}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            ...{ [e.target.id]: e.target.value },
                          });
                        }}
                      />
                      <div style={{ display: "none", color: "red" }}>
                        Please enter numeric value only.
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" for="rechargedate">
                        Enter Recharge Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="rechargedate"
                        required
                        placeholder="Enter Date"
                        value={formData["date"]}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            ...{ [e.target.id]: e.target.value },
                          });
                        }}
                      />
                    </div> 
                  </div> */}
                  {formData.plan ? (
                    cashAvailable > 0 ? (
                      <div className="custom-control custom-checkbox mt-2 mb-1">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="cashbackavail"
                            onClick={() => {
                              setWantCashback(!wantCashback);
                            }}
                          />
                          <label
                            className="form-check-label"
                            for="cashbackavail"
                          >
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              {" "}
                              Use Available Cashback of Rs.{" "}
                              {Math.min(cashAvailable, formData.plan)}
                            </span>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <span style={{ color: "black" }}>
                        Cashback Balance: <b>Rs. {cashAvailable}</b>.
                      </span>
                    )
                  ) : (
                    ""
                  )}
                  <div className="custom-control custom-checkbox mt-2 mb-1">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheckb1"
                      />
                      <label className="form-check-label" for="customCheckb1">
                        I agree{" "}
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#termsModal"
                        >
                          terms and conditions
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="form-button-group transparent"
                style={{ position: "static" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-lg"
                  onClick={submitData}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* App Bottom Menu */}
      <Footer />
      {/* * App Bottom Menu */}

      {/* App Sidebar */}
      <Sidebar />
      {/* * App Sidebar */}
    </div>
  );
};

export default ActivateCard;
