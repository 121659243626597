export const bankinfo = [
  { bankName: "Mehsana Urban Co-op Bank" },
  { bankName: "abhyudaya bank" },
  { bankName: "allahabad bank" },
  { bankName: "American Express Bank Logo" },
  { bankName: "andhra bank" },
  { bankName: "Apna Sahakari Bank" },
  { bankName: "AU Bank Logo" },
  { bankName: "Axis Bank" },
  { bankName: "Bank of Baroda" },
  { bankName: "Bank Of India" },
  { bankName: "Bank Of Maharashtra" },
  { bankName: "Bhartiya Mahila Bank" },
  { bankName: "Canara Bank" },
  { bankName: "Central Bank of India" },
  { bankName: "CITI Bnak Logo" },
  { bankName: "Corporation Bank" },
  { bankName: "DBS Bank Logo" },
  { bankName: "DCB Bank" },
  { bankName: "Dena Bank" },
  { bankName: "Federal Bank" },
  { bankName: "Gujarat State Co-op Bank" },
  { bankName: "Hasti Co-op Bank" },
  { bankName: "HDFC Bank" },
  { bankName: "HSBC Bank Logo" },
  { bankName: "ICICI Bank" },
  { bankName: "IDBI Bank" },
  { bankName: "IDFC First Bank" },
  { bankName: "Indian Overseas Bank " },
  { bankName: "Induslnd Bank Logo" },
  { bankName: "ING Vysya Bank " },
  { bankName: "Karnataka Bank" },
  { bankName: "Karur Vysya Bank" },
  { bankName: "Mahindra kotak Bank Logo" },
  { bankName: "Nainital Bank " },
  { bankName: "Indian Bank" },
  { bankName: "NKGSB Co-op Bank " },
  { bankName: "Oriental Bank of Commerce" },
  { bankName: "Punjab & Maharashtra Co-op Bank" },
  { bankName: "Punjab & Sind Bank " },
  { bankName: "Punjab National Bank" },
  { bankName: "RBL Bank" },
  { bankName: "Saraswat Bank" },
  { bankName: "SBI Bank" },
  { bankName: "SBM Bank" },
  { bankName: "Standard Chartered" },
  { bankName: "State Bank of Mysore" },
  { bankName: "State Bank of Patiala " },
  { bankName: "State Bank of Travancore" },
  { bankName: "Syndicate Bank" },
  { bankName: "Tamilnad Mercantile Bank" },
  { bankName: "The South Indian Bank" },
  { bankName: "UCO Bank" },
  { bankName: "Union Bank of India" },
  { bankName: "United Bank of India" },
  { bankName: "Vijaya Bank" },
  { bankName: "Yes Bank" },
];
