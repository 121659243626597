import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useState, useEffect, useContext } from "react";
import { myContext } from "./MyContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getCookie } from "./Cookie";

const RechargeStatus = () => {
  const [formData, setFormData] = useState({});
  const context = useContext(myContext);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState();
  const [rechargeDetails, setRechargeDetails] = useState();
  const [rechargeStatusDetails, setRechargeStatusDetails] = useState([]);

  const [firstPart, setFirstPart] = useState(true);
  const cookie = getCookie();
  const { id } = useParams();
  var serial = 1;
  useEffect(() => {
    if (!cookie.id) {
      navigate("/");
    }
    var data = new FormData();
    data.append("id", cookie.id);
    axios
      .post("https://api.grewbig.com/index.php/item/userDetails", data)
      .then((d) => {
        setUserDetails(d.data);
        axios
          .post("https://api.grewbig.com/index.php/payment/paymentstat", data)
          .then((d) => {
            setRechargeDetails(d.data);
            axios
              .post(
                "https://api.grewbig.com/index.php/payment/rechargestat",
                data
              )
              .then((d) => {
                setRechargeStatusDetails(d.data);
              })
              .catch((e) => console.log(e));
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div>
      <Header />

      <div id="appCapsule">
        <div align="center" style={{ marginTop: "15px" }}>
          <div
            class="pageTitle badge badge-md badge-primary"
            style={{ fontSize: "20px", padding: "15px" }}
            align="center"
          >
            Recharge Details
          </div>
        </div>

        <div className="section mb-5 p-2">
          <div class="card">
            <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
              {rechargeStatusDetails.length
                ? rechargeStatusDetails.map((val) => {
                    return (
                      <li key={val.id}>
                        <a href="#" class="item">
                          <img
                            src={
                              "./assets/img/" +
                              rechargeDetails.operator.toLowerCase() +
                              ".png"
                            }
                            class="img-logo"
                            alt="GrewBig"
                            style={{
                              width: "40px",
                              position: "absolute",
                              top: "7px",
                              right: "37px",
                            }}
                          />
                          <div class="in">
                            <div>
                              <strong>
                                Monthly Recharge Payment- Plan -{" "}
                                {rechargeDetails.plan}
                              </strong>
                              <div class="text-small text-secondary">
                                Date: {val.date}
                              </div>
                            </div>
                            <div class="text-end">
                              <strong>{val.plan}</strong>
                              <div class="text-small">{val.operator}</div>
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                  })
                : "No Data Found"}
            </ul>
          </div>
        </div>
      </div>

      {/* App Bottom Menu */}
      <Footer />
      {/* * App Bottom Menu */}

      {/* App Sidebar */}
      <Sidebar />
      {/* * App Sidebar */}
    </div>
  );
};

export default RechargeStatus;
