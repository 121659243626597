export function getCookie() {
  var cookie = document.cookie;
  var cookieObj = {};
  cookie
    .toString()
    .split("; ")
    .map((val) => {
      var flag = val.split("=");
      var key = flag[0];
      cookieObj = { ...cookieObj, ...{ [key]: flag[1] } };
    });
  return cookieObj;
}
