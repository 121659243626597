import HeaderA from "./HeaderA";
import FooterA from "./FooterA";
import SidebarA from "./SidebarA";
import { useState, useEffect, useContext } from "react";
import { myContext } from "../MyContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getCookie } from "../Cookie";

const AddBank = () => {
  const navigate = useNavigate();

  const cookie = getCookie();
  const [formData, setFormData] = useState({});

  function submitData(e) {
    e.preventDefault();
    var data = new FormData();
    data.append("bank", formData.bank);

    axios
      .post("https://api.grewbig.com/index.php/item/addBank", data)
      .then((d) => {
        alert("Bank Added Successfuly");
      });
  }

  useEffect(() => {
    if (!cookie.aid) {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <HeaderA />

      <div id="appCapsule">
        <div className="section mt-2 text-center">
          <h1>Add Bank</h1>
        </div>
        <div className="section mb-5 p-2">
          <form action="index.html">
            <div className="card">
              <div className="card-body">
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="name">
                      Bank Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="bank"
                      required
                      placeholder="Enter Bank Name"
                      value={formData["bank"]}
                      onBlur={(e) => {
                        if (!/^[a-z A-Z]+$/g.test(e.target.value.trim())) {
                          e.target.style.borderColor = "red";
                          e.target.nextElementSibling.style.display = "block";
                          setFormData({
                            ...formData,
                            ...{ [e.target.id]: "" },
                          });
                        } else {
                          e.target.style.borderColor = "#DCDCE9";
                          e.target.nextElementSibling.style.display = "none";
                        }
                      }}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          ...{ [e.target.id]: e.target.value },
                        });
                      }}
                    />

                    <div style={{ display: "none", color: "red" }}>
                      The name should contains only alphabets and Spaces and
                      should not be blank.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="form-button-group transparent"
              style={{ bottom: "90px" }}
            >
              <button
                onClick={submitData}
                type="submit"
                id="submitbtn"
                className="btn btn-primary btn-block btn-lg"
              >
                Add Bank
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* App Bottom Menu */}
      <FooterA />
      {/* * App Bottom Menu */}

      {/* App Sidebar */}
      <SidebarA />
      {/* * App Sidebar */}
    </div>
  );
};

export default AddBank;
