import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useState, useEffect, useContext } from "react";
import { myContext } from "./MyContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getCookie } from "./Cookie";

const Transactions = () => {
  const [formData, setFormData] = useState({});
  const context = useContext(myContext);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState();
  const [cardDetails, setCardDetails] = useState([]);
  const [billDetails, setBillDetails] = useState();

  const [firstPart, setFirstPart] = useState(true);
  const cookie = getCookie();
  const { id } = useParams();
  var serial = 1;
  useEffect(() => {
    if (!cookie.id) {
      navigate("/");
    }
    var data = new FormData();
    data.append("id", cookie.id);
    axios
      .post("https://api.grewbig.com/index.php/item/userDetails", data)
      .then((d) => {
        setUserDetails(d.data);
        axios
          .post(
            "https://api.grewbig.com/index.php/item/getAllRechargeDetails",
            data
          )
          .then((d) => {
            setCardDetails(d.data);
          });
      });
  }, []);
  function submitData(e) {
    e.preventDefault();
    if (!(formData.bank && formData.amount)) {
      alert("Please complete all Form fields");
    } else if (
      !(formData.nos1 + formData.nos2 + formData.nos3 + formData.nos4).length ==
      16
    ) {
      alert("Please Enter a 16 Digit Credit Card Number");
    } else if (!document.getElementById("customCheckb1").checked) {
      alert("Please Accept the Terms and Conditions before proceeding");
    } else {
      setFirstPart(false);
    }
  }
  function payment(e) {
    e.preventDefault();
    console.log(userDetails);
    console.log(formData);
    var sendData = new FormData();
    sendData.append("name", userDetails.name);
    sendData.append("email", userDetails.email);
    sendData.append("amount", formData.amount);
    sendData.append("bank", formData.bank);
    sendData.append(
      "creditCardNo",
      formData.nos1 + formData.nos2 + formData.nos3 + formData.nos4
    );
    sendData.append("mobile", userDetails.mobile);
    sendData.append("id", userDetails.id);
    axios
      .post(
        "https://api.grewbig.com/index.php/paymentcard/paymentinitiate",
        sendData
      )
      .then((d) => {
        console.log(d);
        window.location = d.data.url;
      })
      .catch((e) => console.log(e));
  }
  return (
    <div>
      <Header />

      <div id="appCapsule">
        <div align="center" style={{ marginTop: "15px" }}>
          <div
            class="pageTitle badge badge-md badge-primary"
            style={{ fontSize: "20px", padding: "15px" }}
            align="center"
          >
            Transactions
          </div>
        </div>

        <div className="section mb-5 p-2">
          <div class="card">
            <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
              {cardDetails.length
                ? cardDetails.map((val) => {
                    return (
                      <li key={val.id}>
                        <a href="#" class="item">
                          {val.success == 1 ? (
                            <div class="icon-box bg-success">
                              <ion-icon
                                name="checkmark-outline"
                                role="img"
                                class="md hydrated"
                                aria-label="checkmark outline"
                              ></ion-icon>
                            </div>
                          ) : (
                            <div class="icon-box bg-danger">
                              <ion-icon
                                name="close-outline"
                                role="img"
                                class="md hydrated"
                                aria-label="close-circle outline"
                              ></ion-icon>
                            </div>
                          )}

                          <div class="in">
                            <div>
                              <strong>
                                {val.rechargeNo}-{val.operator}
                              </strong>
                              <div class="text-small text-secondary">
                                Plan : {val.plan}
                              </div>
                            </div>
                            <div class="text-end">
                              <strong>Amount Paid- {val.amount}</strong>
                              <div class="text-small">Date-{val.date}</div>
                              <div class="text-small">
                                {val.success == 1 ? (
                                  <span>Cashback Earn-{val.cashbackearn}</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                  })
                : "No Data Found"}
            </ul>
          </div>
        </div>
      </div>

      {/* App Bottom Menu */}
      <Footer />
      {/* * App Bottom Menu */}

      {/* App Sidebar */}
      <Sidebar />
      {/* * App Sidebar */}
    </div>
  );
};

export default Transactions;
