import HeaderA from "./HeaderA";
import FooterA from "./FooterA";
import SidebarA from "./SidebarA";
import { useState, useEffect, useContext } from "react";
import { myContext } from "../MyContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getCookie } from "../Cookie";

const DoRechargeCard = () => {
  function printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  }
  const context = useContext(myContext);
  const navigate = useNavigate();
  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [emidue, setEmiDue] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [rechargeDetails, setRechargeDetails] = useState();
  const [emiDetails, setEmiDetails] = useState([]);
  const [serialno, setserialno] = useState(0);
  const [firstPart, setFirstPart] = useState(true);
  const cookie = getCookie();
  const { id } = useParams();
  var serial = 0;

  useEffect(() => {
    if (!cookie.aid) {
      navigate("/");
    }

    axios
      .get(
        "https://api.grewbig.com/index.php/item/getPendingRechargeDetailsCard"
      )
      .then((d) => {
        setUserDetails(d.data);
        console.log(d.data);
      })
      .catch((e) => console.log(e));
  }, []);

  //   function PayEmi(emiNo) {
  //     var sendData = new FormData();
  //     sendData.append("name", userDetails.name);
  //     sendData.append("email", userDetails.email);
  //     sendData.append("amount", Math.floor(rechargeDetails.amount / 6));
  //     sendData.append("mobile", userDetails.mobile);
  //     sendData.append("id", userDetails.id);
  //     sendData.append("emiNo", emiNo);
  //     axios
  //       .post("https://api.grewbig.com/index.php/emi/emiinitiate", sendData)
  //       .then((d) => {
  //         // console.log(d);
  //         window.location = d.data.url;
  //       })
  //       .catch((e) => console.log(e));
  //   }

  return (
    <div>
      <HeaderA />
      <div
        className="modal show dialogbox"
        id="DialogBasic"
        data-bs-backdrop="static"
        tabindex="-1"
        role="dialog"
        style={{ display: "none", background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "10px",
              maxWidth: "375px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                color: "black",
                fontSize: "15px",
                marginBottom: "20px",
              }}
              align="center"
            >
              Success Status
              <br />
              <div id="successp" style={{ color: "green" }}>
                Recharge Successfully Done
              </div>
              <button
                className="btn btn-md btn-warning"
                style={{ marginTop: "8px", marginLeft: "15px" }}
                onClick={(e) => {
                  e.preventDefault();
                  document.querySelector("#DialogBasic").style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="appCapsule">
        <div align="center" style={{ marginTop: "15px" }}>
          <div
            class="pageTitle badge badge-md badge-primary"
            style={{ fontSize: "20px", padding: "15px" }}
            align="center"
          >
            Bill Paid History
          </div>
        </div>
        <div className="body-date" style={{ marginTop: "10px" }}>
          <div class="container-date">
            <div class="date-range-selector">
              <input
                type="date"
                id="fromDate"
                placeholder="From Date"
                onChange={(e) => {
                  setToDate(e.target.value.split("-").join("/"));
                }}
              />
            </div>
            <div class="date-range-selector">
              <input
                type="date"
                id="toDate"
                placeholder="toDate"
                onChange={(e) => {
                  setFromDate(e.target.value.split("-").join("/"));
                }}
              />
            </div>
            <button
              class="search-button"
              onClick={(d) => {
                d.preventDefault();
                var data = new FormData();
                data.append("to", toDate);
                data.append("from", fromDate);
                console.log(fromDate, toDate);
                axios
                  .post(
                    "https://api.grewbig.com/index.php/item/getCustomSuccessBillDetails",
                    data
                  )
                  .then((d) => {
                    console.log(d);
                    setUserDetails(d.data);
                    console.log(d.data);
                  })
                  .catch((e) => console.log(e));
              }}
            >
              Search
            </button>
          </div>
        </div>
        <input
          type="button"
          onClick={() => printDiv("printableArea")}
          value="PRint The Data"
          className="btn btn-md btn-primary "
          style={{ marginTop: "10px" }}
        />
        <div className="section mb-5 p-2">
          <div class="card" id="printableArea">
            <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
              {userDetails.length
                ? userDetails.map((val, index) => {
                    serial++;
                    return (
                      <li key={val.id}>
                        <strong style={{ paddingLeft: "10px" }}>
                          {serial}
                        </strong>
                        <a href="#" class="item">
                          <img
                            src={"./assets/img/check_mark.png"}
                            class="img-logo"
                            alt="GrewBig"
                            style={{
                              width: "40px",
                            }}
                          />
                          <div class="in">
                            <div>
                              <strong>
                                Name: {val.name} | Credit Card No:{" "}
                                {val.creditCardNo}
                              </strong>
                              <div class="text-small text-secondary">
                                Amount: {val.actualamount} | Bank: {val.bank}
                              </div>
                              <div class="text-small text-secondary">
                                Transaction ID: {val.transaction_id_g} |
                                Customer Mobile: {val.mobile}
                              </div>
                              <div class="text-small text-secondary">
                                Cashback Earn: {val.cashbackearn} | Cashback
                                Use: {val.cashbackuse} | Amount Paid:{" "}
                                {val.amount}
                              </div>
                              <div class="text-small text-secondary">
                                <b>Date And Time</b> : {val.date}
                              </div>
                            </div>
                            <div class="text-end">
                              {/* <button
                                className="btn btn-sm btn-success"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenModal(true);
                                  setRechargeDetails({
                                    rechargeNo: val.rechargeNo,
                                    transaction_id: val.transaction_id,
                                  });
                                }}
                              >
                                Do Recharge
                              </button> */}
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                  })
                : "No Data Found"}
            </ul>
          </div>
        </div>
      </div>

      {/* App Bottom Menu */}
      <FooterA />
      {/* * App Bottom Menu */}

      {/* App Sidebar */}
      <SidebarA />
      {/* * App Sidebar */}
    </div>
  );
};

export default DoRechargeCard;
