import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useState, useEffect, useContext } from "react";
import { myContext } from "./MyContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getCookie } from "./Cookie";

const EmiDetails = () => {
  const context = useContext(myContext);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState();
  const [rechargeDetails, setRechargeDetails] = useState();
  const [emiDetails, setEmiDetails] = useState([]);

  const [firstPart, setFirstPart] = useState(true);
  const cookie = getCookie();
  const { id } = useParams();
  var serial = 1;
  useEffect(() => {
    if (!cookie.id) {
      navigate("/");
    }
    var data = new FormData();
    data.append("id", cookie.id);
    axios
      .post("https://api.grewbig.com/index.php/item/userDetails", data)
      .then((d) => {
        setUserDetails(d.data);
        axios
          .post("https://api.grewbig.com/index.php/payment/paymentstat", data)
          .then((d) => {
            setRechargeDetails(d.data);
            axios
              .post("https://api.grewbig.com/index.php/payment/emistat", data)
              .then((d) => {
                console.log(d);
                setEmiDetails(d.data);
              })
              .catch((e) => console.log(e));
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  }, []);

  function PayEmi(emiNo) {
    var sendData = new FormData();
    sendData.append("name", userDetails.name);
    sendData.append("email", userDetails.email);
    sendData.append("amount", Math.floor(rechargeDetails.amount / 6));
    sendData.append("mobile", userDetails.mobile);
    sendData.append("id", userDetails.id);
    sendData.append("emiNo", emiNo);
    axios
      .post("https://api.grewbig.com/index.php/emi/emiinitiate", sendData)
      .then((d) => {
        // console.log(d);
        window.location = d.data.url;
      })
      .catch((e) => console.log(e));
  }

  return (
    <div>
      <Header />

      <div id="appCapsule">
        <div align="center" style={{ marginTop: "15px" }}>
          <div
            class="pageTitle badge badge-md badge-primary"
            style={{ fontSize: "20px", padding: "15px" }}
            align="center"
          >
            EMI Details
          </div>
        </div>

        <div className="section mb-5 p-2">
          <div class="card">
            <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
              {emiDetails.length
                ? emiDetails.map((val) => {
                    return (
                      <li key={val.id}>
                        <a href="#" class="item">
                          {val.payment_status == 1 ? (
                            <div class="icon-box bg-success">
                              <ion-icon
                                name="checkmark-outline"
                                role="img"
                                class="md hydrated"
                                aria-label="checkmark outline"
                              ></ion-icon>
                            </div>
                          ) : Math.round(
                              (new Date(val.emi_due_date).getTime() -
                                new Date().getTime()) /
                                (1000 * 3600 * 24)
                            ) >= 5 ? (
                            <div class="icon-box bg-secondary">
                              <ion-icon
                                name="ban-outline"
                                role="img"
                                class="md hydrated"
                                aria-label="ban-circle outline"
                              ></ion-icon>
                            </div>
                          ) : Math.round(
                              (new Date(val.emi_due_date).getTime() -
                                new Date().getTime()) /
                                (1000 * 3600 * 24)
                            ) < 5 &&
                            Math.round(
                              (new Date(val.emi_due_date).getTime() -
                                new Date().getTime()) /
                                (1000 * 3600 * 24)
                            ) >= 0 ? (
                            <div class="icon-box bg-warning">
                              <ion-icon
                                name="alert-outline"
                                role="img"
                                class="md hydrated"
                                aria-label="alert-circle outline"
                              ></ion-icon>
                            </div>
                          ) : (
                            <div class="icon-box bg-danger">
                              <ion-icon
                                name="close-outline"
                                role="img"
                                class="md hydrated"
                                aria-label="close-circle outline"
                              ></ion-icon>
                            </div>
                          )}
                          <div class="in">
                            <div>
                              <strong>EMI Due On - {val.emi_due_date}</strong>
                              <div class="text-small text-secondary">
                                {val.payment_status == 1 ? (
                                  <span>
                                    <b style={{ color: "green" }}>Paid</b>
                                    <br />
                                    Transaction Id: {val.transaction_id}
                                  </span>
                                ) : Math.round(
                                    (new Date(val.emi_due_date).getTime() -
                                      new Date().getTime()) /
                                      (1000 * 3600 * 24)
                                  ) < 5 &&
                                  Math.round(
                                    (new Date(val.emi_due_date).getTime() -
                                      new Date().getTime()) /
                                      (1000 * 3600 * 24)
                                  ) >= 0 ? (
                                  <span style={{ color: "orange" }}>
                                    EMI Due, Please Pay Immediately to avoid
                                    card getting Deactivated
                                  </span>
                                ) : Math.round(
                                    (new Date(val.emi_due_date).getTime() -
                                      new Date().getTime()) /
                                      (1000 * 3600 * 24)
                                  ) < 0 ? (
                                  <span style={{ color: "red" }}>
                                    EMI Overdue. Please Pay Immediately
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div class="text-end">
                              <strong>
                                {Math.floor(rechargeDetails.amount / 6)}
                              </strong>
                              {Math.round(
                                (new Date(val.emi_due_date).getTime() -
                                  new Date().getTime()) /
                                  (1000 * 3600 * 24)
                              ) < 5 && val.payment_status != 1 ? (
                                <div>
                                  <button
                                    class="btn btn-sm btn-success"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      PayEmi(val.emiNo);
                                    }}
                                  >
                                    Pay
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                  })
                : "No Data Found"}
            </ul>
          </div>
        </div>
      </div>

      {/* App Bottom Menu */}
      <Footer />
      {/* * App Bottom Menu */}

      {/* App Sidebar */}
      <Sidebar />
      {/* * App Sidebar */}
    </div>
  );
};

export default EmiDetails;
