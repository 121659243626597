import HeaderA from "./HeaderA";
import FooterA from "./FooterA";
import SidebarA from "./SidebarA";
import { useState, useEffect, useContext } from "react";
import { myContext } from "../MyContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getCookie } from "../Cookie";

const ManualCardTopup = () => {
  const [formData, setFormData] = useState({ amount: 0, operator: "" });
  const [plan, setPlan] = useState();
  const [flag1, setflag1] = useState(false);
  const [showOperator, setShowOperator] = useState(false);
  const context = useContext(myContext);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState([]);
  const [rechargeDetails, setRechargeDetails] = useState("");
  const [rechargeStatusDetails, setRechargeStatusDetails] = useState();

  const [firstPart, setFirstPart] = useState(true);
  const cookie = getCookie();
  const { id } = useParams();
  var serial = 1;
  useEffect(() => {
    console.log(rechargeDetails);
    if (rechargeDetails) {
      document.querySelector("#DialogBasic").style.display = "block";
    }
  }, [rechargeDetails]);
  useEffect(() => {
    if (formData.plan && flag1 == true && formData.pmode) {
      setflag1(false);
      axios
        .get("https://api.grewbig.com/index.php/item/otherDetails")
        .then((d) => {
          var amount =
            formData.plan * 12 -
            (d.data[0].discount / 100) * (formData.plan * 12) +
            +d.data[0].cardFees;
          if (formData.pmode == "all") {
            amount = amount - d.data[0].cardFees;
          }
          setFormData({ ...formData, ...{ amount: amount } });
          if (document.getElementById("disinfo")) {
            document.getElementById("disinfo").innerHTML =
              d.data[0].discount +
              "% Discount is applied and one Time Card Fees of " +
              d.data[0].cardFees +
              " added On EMI Payment";
            if (formData.pmode == "all") {
              document.getElementById("disinfo").innerHTML =
                d.data[0].discount +
                "% Discount is applied and one Time Card Fees of " +
                d.data[0].cardFees +
                " is not charged on Full Payment";
            }
          }
        });
    }
  }, [formData.plan, flag1, formData.pmode]);
  useEffect(() => {
    var data = new FormData();
    data.append("operator", formData.operator);
    axios
      .post("https://api.grewbig.com/index.php/item/planDetails", data)
      .then((d) => {
        setPlan(d.data);
      });
  }, [formData.operator]);
  useEffect(() => {
    if (!cookie.aid) {
      navigate("/");
    }

    axios
      .get("https://api.grewbig.com/index.php/item/getInactiveCustomers")
      .then((d) => {
        setUserDetails(d.data);
        console.log(d);
      })
      .catch((e) => console.log(e));
    //     axios
    //       .post("https://api.grewbig.com/index.php/payment/paymentstat", data)
    //       .then((d) => {
    //         setRechargeDetails(d.data);
    //         axios
    //           .post(
    //             "https://api.grewbig.com/index.php/payment/rechargestat",
    //             data
    //           )
    //           .then((d) => {
    //             setRechargeStatusDetails(d.data);
    //           })
    //           .catch((e) => console.log(e));
    //       })
    //       .catch((e) => console.log(e));
    //   })
    //   .catch((e) => console.log(e));
  }, []);

  return (
    <div>
      <HeaderA />
      {rechargeDetails ? (
        <div
          className="modal show dialogbox"
          id="DialogBasic"
          data-bs-backdrop="static"
          tabindex="-1"
          role="dialog"
          style={{ display: "none", background: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog" role="document">
            <div
              className="modal-content"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "10px",
                maxWidth: "375px",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: "15px",
                  marginBottom: "20px",
                  overflowY: "auto",
                }}
                align="center"
              >
                TopUp Card-{" "}
                {userDetails.length ? userDetails[rechargeDetails].name : ""}
                <br />
                <div>
                  {" "}
                  Card No-
                  {userDetails.length
                    ? userDetails[rechargeDetails].cardNumber.substr(0, 15)
                    : ""}
                </div>
                <form action="index.html">
                  <div className="card">
                    <div className="card-body">
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" for="rechargeNumber">
                            Recharge Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="mobile"
                            disabled
                            value={
                              userDetails.length
                                ? userDetails[rechargeDetails].mobile
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group basic">
                        <div className="input-wrapper register-select-custom">
                          <label className="label" for="operator">
                            Select Operator
                          </label>
                          <input
                            className="form-select"
                            id="operator"
                            onClick={() => {
                              setShowOperator(!showOperator);
                            }}
                            style={{ cursor: "pointer" }}
                            placeholder="Select Mobile Operator"
                            value={formData["operator"]}
                          />
                          {formData["operator"].length ? (
                            <img
                              src={
                                "./assets/img/" +
                                formData["operator"].toLowerCase() +
                                ".png"
                              }
                              class="img-logo"
                              alt="GrewBig"
                              style={{
                                width: "40px",
                                position: "absolute",
                                top: "7px",
                                right: "37px",
                              }}
                            />
                          ) : (
                            ""
                          )}
                          {showOperator ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                              class="operator-select"
                            >
                              <span
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    ...{ operator: "Airtel" },
                                  });
                                  setShowOperator(!showOperator);
                                }}
                              >
                                <img
                                  src="./assets/img/airtel.png"
                                  class="img-logo"
                                  alt="GrewBig"
                                  style={{ width: "40px" }}
                                />
                                Airtel Prepaid
                              </span>
                              <span
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    ...{ operator: "Jio" },
                                  });
                                  setShowOperator(!showOperator);
                                }}
                              >
                                <img
                                  src="./assets/img/jio.png"
                                  class="img-logo"
                                  alt="GrewBig"
                                  style={{ width: "40px" }}
                                />
                                Jio Prepaid
                              </span>
                              <span
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    ...{ operator: "BSNL" },
                                  });
                                  setShowOperator(!showOperator);
                                }}
                              >
                                <img
                                  src="./assets/img/bsnl.png"
                                  class="img-logo"
                                  alt="GrewBig"
                                  style={{ width: "40px" }}
                                />
                                Bsnl Prepaid
                              </span>
                              <span
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    ...{ operator: "VI" },
                                  });
                                  setShowOperator(!showOperator);
                                }}
                              >
                                <img
                                  src="./assets/img/vi.png"
                                  class="img-logo"
                                  alt="GrewBig"
                                  style={{ width: "40px" }}
                                />
                                VI Prepaid
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" for="idproofnumber">
                            Enter Mobile Plan
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="plan"
                            required
                            placeholder="Enter Mobile Plan Ex- 199"
                            onBlur={(e) => {
                              if (!/^[0-9]+$/g.test(e.target.value.trim())) {
                                e.target.style.borderColor = "red";
                                e.target.nextElementSibling.style.display =
                                  "block";
                                setFormData({
                                  ...formData,
                                  ...{ [e.target.id]: "" },
                                });
                              } else {
                                setflag1(true);

                                e.target.style.borderColor = "#DCDCE9";
                                e.target.nextElementSibling.style.display =
                                  "none";
                              }
                            }}
                            value={formData["plan"]}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                ...{ [e.target.id]: e.target.value },
                              });
                            }}
                          />
                          <div style={{ display: "none", color: "red" }}>
                            Please enter numeric value only.
                          </div>
                        </div>
                      </div>

                      <div className="form-group basic">
                        <div className="input-wrapper register-select-custom">
                          <label className="label" for="pmode">
                            Payment Mode
                          </label>
                          <select
                            className="form-select"
                            id="pmode"
                            onChange={(e) => {
                              setflag1(true);
                              setFormData({
                                ...formData,
                                ...{ [e.target.id]: e.target.value },
                              });
                            }}
                          >
                            <option selected value="">
                              Select Payment Mode
                            </option>
                            <option value="emi">EMI</option>
                            <option value="all">All Pay</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" for="amount">
                            Amount for One year
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="amount"
                            value={formData.amount}
                            disabled
                          />
                          <div style={{ color: "green" }} id="disinfo"></div>
                        </div>
                      </div>
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" for="rechargedate">
                            Enter First Recharge Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="rechargedate"
                            required
                            placeholder="Enter Date"
                            value={formData["date"]}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                ...{ [e.target.id]: e.target.value },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div id="successp" style={{ color: "green", display: "none" }}>
                  Plan Duration Added Successfully
                </div>
                <button
                  className="btn btn-md btn-primary"
                  style={{ marginTop: "8px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      !(formData.operator && formData.plan && formData.pmode)
                    ) {
                      alert("Please complete all Form fields");
                    } else {
                      var sendData = new FormData();

                      sendData.append("amount", formData.amount);
                      sendData.append("operator", formData.operator);
                      sendData.append("plan", formData.plan);
                      sendData.append("mode", formData.pmode);
                      sendData.append(
                        "id",
                        userDetails[rechargeDetails].user_idM
                      );
                      sendData.append("rechargedate", formData.rechargedate);
                      axios
                        .post(
                          "https://api.grewbig.com/index.php/item/cardtopup",
                          sendData
                        )
                        .then((d) => {
                          console.log(d);
                          alert(
                            `Card Top-up For ${userDetails[rechargeDetails].name}- ${userDetails[rechargeDetails].mobile} is done Successfully`
                          );

                          axios
                            .get(
                              "https://api.grewbig.com/index.php/item/getInactiveCustomers"
                            )
                            .then((d) => {
                              setUserDetails(d.data);
                              setRechargeDetails("");
                              document.querySelector(
                                "#DialogBasic"
                              ).style.display = "none";
                            })
                            .catch((e) => console.log(e));
                        })
                        .catch((e) => console.log(e));
                    }
                  }}
                >
                  TopUp Card
                </button>
                <button
                  className="btn btn-md btn-warning"
                  style={{ marginTop: "8px", marginLeft: "15px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector("#successp").style.display = "none";
                    document.querySelector("#DialogBasic").style.display =
                      "none";
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div id="appCapsule">
        <div align="center" style={{ marginTop: "15px" }}>
          <div
            class="pageTitle badge badge-md badge-primary"
            style={{ fontSize: "20px", padding: "15px" }}
            align="center"
          >
            Inactive Customers- Card Topup
          </div>
        </div>

        <div className="section mb-5 p-2">
          <div class="card">
            <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
              {userDetails.length
                ? userDetails.map((val, index) => {
                    return (
                      <li key={val.id}>
                        <a href="#" class="item">
                          <div class="in">
                            <div>
                              <strong>
                                Name: {val.name} | Mobile: {val.mobile}
                              </strong>
                              <div class="text-small text-secondary">
                                CardNo: {val.cardNumber.substr(0, 15)}
                              </div>
                            </div>

                            <div class="text-end">
                              <button
                                className="btn btn-sm btn-success"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setRechargeDetails(index);
                                }}
                              >
                                TopUp Card
                              </button>
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                  })
                : "No Data Found"}
            </ul>
          </div>
        </div>
      </div>

      {/* App Bottom Menu */}
      <FooterA />
      {/* * App Bottom Menu */}

      {/* App Sidebar */}
      <SidebarA />
      {/* * App Sidebar */}
    </div>
  );
};

export default ManualCardTopup;
