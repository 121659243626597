import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { myContext } from "./MyContext";
import { path } from "./path";
import { getCookie } from "./Cookie";

const Login = () => {
  const cookie = getCookie();
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const context = useContext(myContext);
  const [msg, setmsg] = useState("");
  const [load, setLoad] = useState(false);
  useEffect(() => {
    if (cookie.id) {
      navigate("/home");
    }
  });
  useEffect(() => {
    setLoad(true);
    // var loader = document.getElementById("loader");
    setTimeout(() => {
      // loader.setAttribute(
      //   "style",
      //   "pointer-events: none; opacity: 0; transition: 0.2s ease-in-out;"
      // );
      setTimeout(() => {
        setLoad(false);
      }, 1000);
    }, 450);
  }, []);
  useEffect(() => {
    if (
      window.localStorage.getItem("msg") &&
      window.localStorage.getItem("msgcount") == 1
    ) {
      setmsg(window.localStorage.getItem("msg"));
      window.localStorage.setItem("msgcount", 0);
      window.localStorage.removeItem("msg");
    }
  });
  function handleSubmit(e) {
    e.preventDefault();
    const dataf = new FormData();
    dataf.append("mobile", formData.mobile);
    dataf.append("password", formData.password);
    if (formData.mobile == "admin") {
      axios
        .post("https://api.grewbig.com/index.php/item/loginadmin", dataf)
        .then((data) => {
          if (data.data.id) {
            context.setMain(data.data.id);
            document.cookie = `aid=${data.data.id}; expires=Thu, 10 Feb 2029 23:00:00 IST; path='/'`;
            navigate("/customerdetails");
            // console.log("hi");
          } else {
            window.localStorage.setItem("msg", "Invalid Login Details");
            window.localStorage.setItem("msgcount", 1);
            setmsg(window.localStorage.getItem("msg"));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      axios
        .post("https://api.grewbig.com/index.php/item/login", dataf)
        .then((data) => {
          if (data.data.id) {
            context.setMain(data.data.id);
            document.cookie = `id=${data.data.id}; expires=Thu, 10 Feb 2029 23:00:00 IST; path='/'`;

            navigate("/home");
            // console.log("hi");
          } else {
            window.localStorage.setItem("msg", "Invalid Login Details");
            window.localStorage.setItem("msgcount", 1);
            setmsg(window.localStorage.getItem("msg"));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
  return (
    <div
      style={{
        backgroundImage: "url('assets/img/bg2.jpg')",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      {load ? (
        <div id="loader">
          <img
            src={path + "/assets/img/logo-grewbig.png"}
            alt="icon"
            class="loading-icon"
          />
        </div>
      ) : (
        ""
      )}
      <div class="appHeader no-border transparent position-absolute">
        <div class="left">
          <a href="#" class="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div class="pageTitle"></div>
        <div class="right"></div>
      </div>

      <div id="appCapsule">
        <div className="section mt-2 text-center">
          <img
            src="./assets/img/logo-grewbig.png"
            class="img-logo"
            alt="GrewBig"
          />
          <h1>Login</h1>
          {msg.length ? (
            <div
              class="alert alert-primary alert-dismissible fade show mb-2"
              role="alert"
            >
              {msg}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : (
            ""
          )}
        </div>
        <div class="section mb-5 p-2">
          <form action="index.html">
            <div class="card">
              <div class="card-body pb-1">
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="mobile">
                      Mobile
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="mobile"
                      value={formData.mobile}
                      placeholder="Your Mobile Number"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          ...{ [e.target.id]: e.target.value },
                        });
                      }}
                    />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>

                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="password1">
                      Password
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      autocomplete="off"
                      placeholder="Your password"
                      value={formData.password}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          ...{ [e.target.id]: e.target.value },
                        });
                      }}
                    />

                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                  <input
                    type="checkbox"
                    style={{ marginRight: "8px" }}
                    onClick={() => {
                      console.log("hello");
                      var x = document.getElementById("password");
                      if (x.type === "password") {
                        x.type = "text";
                      } else {
                        x.type = "password";
                      }
                    }}
                  />
                  Show Password
                </div>
              </div>
            </div>

            <div class="form-links mt-2">
              <div>
                <Link to="/register" class="btn btn-secondary">
                  Register Now
                </Link>
              </div>
              <div>
                <a href="app-forgot-password.html" class="btn btn-light">
                  Forgot Password?
                </a>
              </div>
            </div>

            <div class="form-button-group  transparent">
              <button
                type="submit"
                class="btn btn-primary btn-block btn-lg"
                onClick={handleSubmit}
              >
                Log in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
