import { Link, useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="appBottomMenu">
      <Link to="/creditcardpay" className="item active">
        <div className="col">
          <ion-icon name="card-outline"></ion-icon>
          <strong>Credit Card Bill Pay</strong>
        </div>
      </Link>
      <Link to="/activatecard" className="item">
        <div className="col">
          <img
            src="./assets/img/mobile-icn.png"
            class="img-logo"
            alt="GrewBig"
            style={{ width: "30px" }}
          />
          <strong>Mobile Recharge </strong>
        </div>
      </Link>

      <Link to="/home" className="item">
        <div className="col">
          <ion-icon name="home-outline"></ion-icon>
          <strong>Home</strong>
        </div>
      </Link>
      <Link to="/transactions" className="item">
        <div className="col">
          <ion-icon name="document-text-outline"></ion-icon>
          <strong>Recharge Transactions</strong>
        </div>
      </Link>
      <Link to="/credittransactions" className="item">
        <div className="col">
          <ion-icon name="document-text-outline"></ion-icon>
          <strong>Credit Transactions</strong>
        </div>
      </Link>
    </div>
  );
};
export default Footer;
