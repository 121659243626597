import Home from "./Home";
import Register from "./Register";
import CustomerDetails from "./admin/CustomerDetails";
import CustomerDetailsCard from "./admin/CustomerDetailsCard";

import AddBank from "./admin/AddBank";

import DoRecharge from "./admin/DoRecharge";
import DoRechargeCard from "./admin/DoRechargeCard";

import ManualCardTopup from "./admin/ManualCardTopup";

import ActivateCard from "./ActivateCard";
import VerifyDetails from "./VerifyDetails";
import Test from "./Test";
import { Routes, Route } from "react-router-dom";
import { Provider } from "./MyContext";
import { useState } from "react";
import CreditCardPay from "./CreditCardPay";
import Transactions from "./Transactions";
import CreditTransactions from "./CreditTransactions";

import RechargeStatus from "./RechargeStatus";
// import { Suspense, lazy } from "react";
// const Login = lazy(() => import("./Login"));
import Login from "./Login";
import EmiDetails from "./EmiDetails";
import CustomerCardManual from "./admin/CustomerCardManual";
import CustomerSupport from "./CustomerSupport";
function App() {
  const [mainState, setMainState] = useState(0);
  const [sideBar, setSideBar] = useState(false);
  return (
    <div>
      <Provider
        value={{
          main: mainState,
          setMain: setMainState,
          sideBar: sideBar,
          setSideBar: setSideBar,
        }}
      >
        <Routes>
          <Route path="/customerdetails" element={<CustomerDetails />} />
          <Route path="/customersupport" element={<CustomerSupport />} />
          <Route
            path="/customerdetailscard"
            element={<CustomerDetailsCard />}
          />
          <Route path="/addbank" element={<AddBank />} />
          <Route path="/manualcardtopup" element={<ManualCardTopup />} />
          <Route path="/dorecharge" element={<DoRecharge />} />
          <Route path="/dorechargecard" element={<DoRechargeCard />} />
          <Route path="/customercardmanual" element={<CustomerCardManual />} />
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/home" element={<Home />} />
          <Route path="/home/:id" element={<Home />} />
          <Route path="/test" element={<Test />} />
          <Route path="/activatecard" element={<ActivateCard />} />
          <Route path="/rechargestatus" element={<RechargeStatus />} />
          <Route path="/emidetails" element={<EmiDetails />} />
          <Route path="/verifydetails" element={<VerifyDetails />} />
          <Route path="/creditcardpay" element={<CreditCardPay />} />
          <Route path="/creditcardpay/:id" element={<CreditCardPay />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/credittransactions" element={<CreditTransactions />} />
        </Routes>
      </Provider>
      {/* <Test /> */}
    </div>
  );
}

export default App;
