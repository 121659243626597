import { Link, useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="appBottomMenu">
      <Link to="/customerdetails" className="item active">
        <div className="col">
          <ion-icon name="card-outline"></ion-icon>
          <strong>Recharge Requests</strong>
        </div>
      </Link>
      <Link to="/dorecharge" className="item">
        <div className="col">
          <ion-icon name="document-text-outline"></ion-icon>
          <strong>Recharge History</strong>
        </div>
      </Link>
      <Link to="/customerdetailscard" className="item active">
        <div className="col">
          <ion-icon name="card-outline"></ion-icon>
          <strong>Bill Requests</strong>
        </div>
      </Link>
      <Link to="/dorechargecard" className="item">
        <div className="col">
          <ion-icon name="document-text-outline"></ion-icon>
          <strong>Bill History</strong>
        </div>
      </Link>
      {/* <Link to="/home" className="item">
        <div className="col">
          <ion-icon name="home-outline"></ion-icon>
          <strong>Home</strong>
        </div>
      </Link>
      <Link to="/rechargestatus" className="item">
        <div className="col">
          <ion-icon name="phone-portrait-outline"></ion-icon>
          <strong>Recharge Status</strong>
        </div>
      </Link> */}
      <a
        className="item"
        onClick={() => {
          document.cookie =
            "aid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

          navigate("/");
        }}
      >
        <div className="col">
          <ion-icon name="log-out-outline"></ion-icon>
          <strong>Logout</strong>
        </div>
      </a>
    </div>
  );
};
export default Footer;
